import { useEffect, useState } from "react";

interface WindowSize {
    width: number;
    height: number;
}

const debounce = (fn: () => void, ms = 300): EventListener => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn(), ms);
    };
};

export const useWindowSize = (): WindowSize => {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const handleResize = (): void => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    useEffect(() => {
        window.addEventListener("resize", debounce(handleResize, 500));
    }, []);

    return { width, height };
};
