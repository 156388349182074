import { useLocation } from "react-router-dom";

import { Container } from "@mui/material";

import LoadingSpinner from "../../components/LoadingSpinner";
import PageHeader from "../../components/PageHeader";
import { useGetFolders } from "../../features/stock/api/useGetFolders";
import FoldersList from "../../features/stock/components/FoldersList";

function StockFolderListPage() {
    const { pathname } = useLocation();
    const step = pathname.substring(1);

    const foldersQuery = useGetFolders(step);

    return (
        <>
            <PageHeader title={step} />
            <Container sx={{ py: 4 }} maxWidth="sm">
                {foldersQuery.isLoading || !foldersQuery.data ? (
                    <LoadingSpinner />
                ) : (
                    <FoldersList folders={foldersQuery.data} />
                )}
            </Container>
        </>
    );
}

export default StockFolderListPage;
