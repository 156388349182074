import { useQueries } from "@tanstack/react-query";

import { getFolders } from "./actions";

export function useGetAllFolders() {
    const results = useQueries({
        queries: [
            { queryKey: ["folders", "ramassage"], queryFn: () => getFolders("ramassage") },
            { queryKey: ["folders", "grappage"], queryFn: () => getFolders("grappage") },
            { queryKey: ["folders", "stockage"], queryFn: () => getFolders("stockage") },
        ],
    });

    return results;
}
