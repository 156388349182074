import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import WarehouseRoundedIcon from "@mui/icons-material/WarehouseRounded";
import {
    BottomNavigation,
    BottomNavigationAction,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Typography,
} from "@mui/material";
import { useAuthContext } from "../features/auth/contexts/authContext";
import { ContractType } from "../features/auth/types/contractType";

function Navigation() {
    const { user } = useAuthContext();

    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [value, setValue] = useState(pathname);

    const [isLogoutConfirmationOpen, setIsLogoutConfirmationOpen] = useState(false);

    if (!user) return null;

    return (
        <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 1 }}>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(_event, newValue) => setValue(newValue)}
            >
                {user.admin_contrat_type === ContractType.CDI ? (
                    <BottomNavigationAction
                        component={Link}
                        to="/"
                        value="/"
                        label="Stock"
                        icon={<WarehouseRoundedIcon />}
                    />
                ) : null}
                <BottomNavigationAction
                    component={Link}
                    to="/dashboard"
                    value="/dashboard"
                    label="Gestion"
                    icon={<DashboardRoundedIcon />}
                />
                <BottomNavigationAction
                    onClick={() => setIsLogoutConfirmationOpen(true)}
                    label="Déconnexion"
                    icon={<LogoutRoundedIcon />}
                />
                <Dialog open={isLogoutConfirmationOpen} disableRestoreFocus>
                    <DialogTitle>Déconnexion</DialogTitle>
                    <DialogContent>
                        <Typography>Voulez-vous vraiment vous déconnecter ? </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsLogoutConfirmationOpen(false)}>Annuler</Button>
                        <Button onClick={() => navigate("logout")}>Déconnexion</Button>
                    </DialogActions>
                </Dialog>
            </BottomNavigation>
        </Paper>
    );
}

export default Navigation;
