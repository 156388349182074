function Page404() {
    return (
        <div>
            <h1>Page 404</h1>
            <p>Vous ne devriez pas être là</p>
        </div>
    );
}

export default Page404;
