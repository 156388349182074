import { useQuery } from "@tanstack/react-query";

import { Ramassage } from "../types/ramassage";
import { getRamassageProducts } from "./actions";

type RamassageProductsOptions = {
    onSuccess?: (data: Ramassage) => void;
};

export function useGetRamassageProducts(folderId: string, options: RamassageProductsOptions) {
    const query = useQuery({
        queryKey: ["folders", "ramassage", folderId],
        queryFn: () => getRamassageProducts(folderId),
        staleTime: 0,
        ...options,
    });

    return query;
}
