import { useFormContext, useWatch } from "react-hook-form";

import { ErrorMessage } from "@hookform/error-message";
import { Divider, FormHelperText, InputAdornment, InputLabel, Stack } from "@mui/material";

import FormNumber from "../../../components/form/FormNumber";
import FormRadio from "../../../components/form/FormRadio";
import FormText from "../../../components/form/FormText";
import { CustomerReview } from "../types/customer-review";

function ShotIsFiredForm() {
    const { control, setValue, formState } = useFormContext();

    const durationRespected = useWatch({ control, name: "duree_respecte" });
    const areBlanks = useWatch({ control, name: "blancs" });
    const customerContacted = useWatch({ control, name: "client_contact" });
    const feedback = useWatch({ control, name: "retour_autre" });

    const durationMinutes = useWatch({ control, name: "durationMinutes", defaultValue: 0 });
    const durationSeconds = useWatch({ control, name: "durationSeconds", defaultValue: 0 });
    const durationMilliseconds = durationMinutes * 60 * 1000 + durationSeconds * 1000;
    setValue("duree_reel", durationMilliseconds);

    const customerReviewOptions = [
        { value: CustomerReview.DIDNT_LIKE, label: "J'ai pas aimé" },
        { value: CustomerReview.GOOD_BUT_SEEN_BETTER, label: "Bien mais vu mieux" },
        { value: CustomerReview.PERFECT, label: "Parfait magnifique bravo" },
        { value: CustomerReview.TOO_LONG, label: "Trop long" },
        { value: CustomerReview.TOO_SHORT, label: "Trop court" },
    ];

    const booleanOptions = [
        { value: "1", label: "Oui" },
        { value: "0", label: "Non" },
    ];

    return (
        <>
            <Divider />

            <FormRadio
                row
                name="duree_respecte"
                label="La durée du feu a été respectée ?"
                options={booleanOptions}
                boolean
            />
            {durationRespected === false ? (
                <Stack>
                    <InputLabel>Quelle a été la durée réelle du feu ?</InputLabel>
                    <Stack direction="row" spacing={5}>
                        <FormNumber
                            name="durationMinutes"
                            label=""
                            InputProps={{
                                endAdornment: <InputAdornment position="start">min</InputAdornment>,
                                inputProps: { min: 0 },
                            }}
                        />
                        <FormNumber
                            name="durationSeconds"
                            label=""
                            InputProps={{
                                endAdornment: <InputAdornment position="start">sec</InputAdornment>,
                                inputProps: { min: 0 },
                            }}
                        />
                    </Stack>
                    <ErrorMessage
                        errors={formState.errors}
                        name="duree_reel"
                        render={({ message }) => <FormHelperText error>{message}</FormHelperText>}
                    />
                </Stack>
            ) : null}

            <Divider />

            <FormRadio
                row
                name="blancs"
                label="Il y a t'il eu des blancs ?"
                options={booleanOptions}
                boolean
            />
            {areBlanks === true ? (
                <FormText
                    name="blancs_raison"
                    label="Pourquoi il y a t'il eu des blancs ?"
                    multiline
                />
            ) : null}

            <Divider />

            <FormRadio
                row
                name="client_contact"
                label="Avez-vous eu le client après le tir ?"
                options={booleanOptions}
                boolean
            />
            {customerContacted === true ? (
                <FormRadio
                    name="client_avis"
                    label="Qu'a dit le client ?"
                    options={customerReviewOptions}
                />
            ) : null}

            <Divider />

            <FormRadio
                row
                name="retour_autre"
                label="Il y a t'il des retours ?"
                options={booleanOptions}
                boolean
            />
            {feedback === true ? (
                <FormText name="retour_commentaire" label="Quels sont les retours ?" multiline />
            ) : null}
        </>
    );
}

export default ShotIsFiredForm;
