import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Container, Paper, Stack, Typography } from "@mui/material";

import LoadingSpinner from "../../components/LoadingSpinner";
import PageHeader from "../../components/PageHeader";
import datetime from "../../config/datetime";
import { useGetRamassageProducts } from "../../features/stock/api/useGetRamassageProducts";
import { useRamassageValidate } from "../../features/stock/api/useRamassageValidate";
import CancelRamassageDialog from "../../features/stock/components/ramassage/CancelRamassageDialog";
import RamassageProductsList from "../../features/stock/components/ramassage/RamassageProductsList";

function StockRamassagePage() {
    const navigate = useNavigate();
    const { folderId } = useParams();
    const [open, setOpen] = useState(false);

    const ramassageQuery = useGetRamassageProducts(folderId as string, {
        onSuccess: (data) => (data.ramasse === 1 ? setOpen(true) : setOpen(false)),
    });

    const isComplete = !ramassageQuery.data?.depot?.find((depot) => depot.quantite_restante > 0);

    const ramassageValidateQuery = useRamassageValidate();
    const handleOnValidate = () => {
        ramassageValidateQuery.mutate(folderId as string);
        navigate("/");
    };

    return (
        <>
            <PageHeader title="Ramassage" />
            {ramassageQuery.isLoading || !ramassageQuery.data ? (
                <LoadingSpinner />
            ) : (
                <>
                    <Paper elevation={1}>
                        <Container maxWidth="sm" sx={{ pb: 2 }}>
                            <Stack alignItems="center">
                                <Typography variant="h5" fontWeight="bold" textAlign="center">
                                    {ramassageQuery.data?.nom}
                                </Typography>
                                <Typography>
                                    {datetime(ramassageQuery.data?.date).format("LL")}
                                </Typography>
                            </Stack>
                        </Container>
                    </Paper>
                    <Container sx={{ py: 4 }} maxWidth="sm">
                        <Stack spacing={3}>
                            {isComplete ? (
                                <Button onClick={handleOnValidate} variant="contained">
                                    Valider ramassage
                                </Button>
                            ) : null}
                            <RamassageProductsList
                                products={ramassageQuery.data.liste}
                                depots={ramassageQuery.data.depot}
                            />
                        </Stack>
                    </Container>

                    <CancelRamassageDialog open={open} onClose={() => setOpen(false)} />
                </>
            )}
        </>
    );
}

export default StockRamassagePage;
