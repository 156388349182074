import { useQuery } from "@tanstack/react-query";

import { getFolders } from "./actions";

export function useGetFolders(foldersType: string) {
    const query = useQuery({
        queryKey: ["folders", foldersType],
        queryFn: () => getFolders(foldersType),
    });

    return query;
}
