import { useMutation } from "@tanstack/react-query";

import { queryClient } from "../../../config/react-query";
import { useToast } from "../../../hooks/useToast";
import { ramasserProduct } from "./actions";

export function usePostRamassageProduct() {
    const { successToast } = useToast();

    const mutation = useMutation({
        mutationFn: ramasserProduct,
        onSuccess: () => successToast("Ramassage validé !"),
        onSettled: (_data, _err, request) => {
            queryClient.invalidateQueries({
                queryKey: ["folders", "ramassage", request.dossier_id],
            });
        },
    });

    return mutation;
}
