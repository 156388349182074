import { useQuery } from "@tanstack/react-query";

import { getCosts } from "./actions";
import { QUERY_KEY } from "./config";

export function useGetCosts(contractId: string) {
    const query = useQuery({
        queryKey: [QUERY_KEY, contractId, "costs"],
        queryFn: () => getCosts(contractId),
    });

    return query;
}
