import { useState } from "react";

import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
    Button,
    IconButton,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Stack,
    Typography,
} from "@mui/material";

import type { Folder } from "../../types/folder";

type Props = {
    folder: Folder;
    onClick: () => void;
};

function FolderItem({ folder, onClick }: Props) {
    const [showList, setShowList] = useState(false);

    const handleOnClick = () => {
        setShowList((prev) => !prev);
    };

    return (
        <Stack>
            <Paper elevation={1} sx={{ width: "100%" }}>
                <ListItem
                    disablePadding
                    secondaryAction={
                        <IconButton onClick={onClick}>
                            <KeyboardArrowRightIcon />
                        </IconButton>
                    }
                >
                    <ListItemButton sx={{ py: 2 }} onClick={onClick}>
                        {folder.valider ? (
                            <ListItemIcon>
                                <CheckRoundedIcon color="success" />
                            </ListItemIcon>
                        ) : null}
                        <ListItemText primary={folder.nom} secondary={folder.lieu} />
                    </ListItemButton>
                </ListItem>
            </Paper>
            {folder.depot ? (
                <>
                    <Button
                        onClick={handleOnClick}
                        sx={{ p: 0, fontSize: 12, alignSelf: "flex-start" }}
                    >
                        Afficher/masquer les dépots
                    </Button>
                    {showList
                        ? folder.depot.map(({ numero, poids }) => (
                              <Stack key={numero} direction="row" justifyContent="space-between">
                                  <Typography>{numero}</Typography>
                                  <Typography>{poids} Kg</Typography>
                              </Stack>
                          ))
                        : null}
                </>
            ) : null}
        </Stack>
    );
}

export default FolderItem;
