import { useQuery } from "@tanstack/react-query";

import { getWorkSites } from "./actions";
import { QUERY_KEY } from "./config";

export function useGetWorkSites() {
    const query = useQuery({
        queryKey: [QUERY_KEY],
        queryFn: getWorkSites,
    });

    return query;
}
