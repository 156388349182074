import { useState } from "react";
import { useParams } from "react-router-dom";

import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import LocalShippingRoundedIcon from "@mui/icons-material/LocalShippingRounded";
import { Container, Stack } from "@mui/material";

import Accordion from "../../components/Accordion";
import LoadingSpinner from "../../components/LoadingSpinner";
import PageHeader from "../../components/PageHeader";
import { useGetRoadmap } from "../../features/roadmap/api/useGetRoadmap";
import DayStepper from "../../features/roadmap/components/DayStepper";
import RoadmapDay from "../../features/roadmap/components/RoadmapDay";
import RoadmapInfos from "../../features/roadmap/components/RoadmapInfos";
import RoadmapTransport from "../../features/roadmap/components/RoadmapTransport";

function RoadmapDetailsPage() {
    const { id } = useParams();
    const [currentDay, setCurrentDay] = useState(1);

    const roadmapQuery = useGetRoadmap(id as string);

    const handleOnClickBack = () => setCurrentDay((prev) => prev - 1);
    const handleOnClickNext = () => setCurrentDay((prev) => prev + 1);

    return (
        <>
            <PageHeader
                title={`Feuille de route - ${roadmapQuery.data?.jours_chantier[0].chantier}`}
            />
            {roadmapQuery.isLoading || !roadmapQuery.data ? (
                <LoadingSpinner />
            ) : (
                <Container sx={{ py: 4 }} maxWidth="sm">
                    <Stack spacing={5}>
                        <Stack>
                            <Accordion title="Informations chantier" icon={<InfoRoundedIcon />}>
                                <RoadmapInfos roadmap={roadmapQuery.data} />
                            </Accordion>
                            <Accordion title="Transport" icon={<LocalShippingRoundedIcon />}>
                                <RoadmapTransport roadmap={roadmapQuery.data} />
                            </Accordion>
                        </Stack>
                        <Stack spacing={3}>
                            <DayStepper
                                currentDay={currentDay}
                                steps={roadmapQuery.data.jours_chantier.length}
                                onClickBack={handleOnClickBack}
                                onClickNext={handleOnClickNext}
                            />
                            <RoadmapDay roadmap={roadmapQuery.data} currentDay={currentDay} />
                        </Stack>
                    </Stack>
                </Container>
            )}
        </>
    );
}

export default RoadmapDetailsPage;
