import { useState } from "react";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import QrCode2RoundedIcon from "@mui/icons-material/QrCode2Rounded";
import { Box, Button, Stack, Typography } from "@mui/material";

import { useToast } from "../../../../hooks/useToast";
import { Product } from "../../types/product";
import CancelDialog from "./CancelDialog";
import QuantityDialog from "./QuantityDialog";
import ScannerModal from "./ScannerModal";

type Props = {
    product: Product;
};

function ProductItemRamassage({ product }: Props) {
    const { errorToast } = useToast();
    const [openModal, setOpenModal] = useState(false);
    const [openQuantityDialog, setOpenQuantityDialog] = useState(false);
    const [openCancelDialog, setOpenCancelDialog] = useState(false);

    const isPickedUp = product.quantite_ramassee === product.quantite;
    const stateColor = isPickedUp ? "success" : "warning";

    const handleOnScan = (code: string) => {
        const sameProduct = parseInt(product.id, 10) === parseInt(code, 10);

        if (!sameProduct) {
            return errorToast("Les produits ne correspondent pas");
        }
        setOpenModal(false);
        return setOpenQuantityDialog(true);
    };

    return (
        <>
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={5}>
                <Stack direction="row" spacing={2}>
                    <Box sx={{ width: 2, minHeight: "100%", bgcolor: `${stateColor}.main` }} />
                    <Stack>
                        <Typography variant="caption">{product.nom}</Typography>
                        <Typography variant="caption"> {product.reference}</Typography>
                        <Typography variant="caption" sx={{ color: `${stateColor}.main` }}>
                            Quantité : {product.quantite_ramassee} / {product.quantite}
                        </Typography>
                        {product.ligne.map((ligne) => (
                            <Typography variant="caption" key={ligne.numero_ligne}>
                                Ligne {ligne.numero_ligne} : {ligne.numero_ligne_cobra} /{" "}
                                {ligne.retard} / Qte : {ligne.qte}
                            </Typography>
                        ))}
                    </Stack>
                </Stack>
                {isPickedUp ? (
                    <Button
                        color="success"
                        sx={{
                            minWidth: 0,
                            borderRadius: 50,
                            width: 40,
                            height: 40,
                        }}
                        variant="outlined"
                        onClick={() => setOpenCancelDialog(true)}
                    >
                        <CloseRoundedIcon />
                    </Button>
                ) : (
                    <Button
                        sx={{
                            minWidth: 0,
                            borderRadius: 50,
                            width: 40,
                            height: 40,
                        }}
                        variant="outlined"
                        onClick={() => setOpenModal(true)}
                    >
                        <QrCode2RoundedIcon />
                    </Button>
                )}
            </Stack>

            {openModal ? (
                <ScannerModal
                    onScan={handleOnScan}
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    product={product}
                />
            ) : null}

            {openQuantityDialog ? (
                <QuantityDialog
                    open={openQuantityDialog}
                    onClose={() => setOpenQuantityDialog(false)}
                    product={product}
                />
            ) : null}

            {openCancelDialog ? (
                <CancelDialog
                    open={openCancelDialog}
                    onClose={() => setOpenCancelDialog(false)}
                    product={product}
                />
            ) : null}
        </>
    );
}

export default ProductItemRamassage;
