import { useMutation } from "@tanstack/react-query";

import { queryClient } from "../../../config/react-query";
import { useToast } from "../../../hooks/useToast";
import { ramassageCancel } from "./actions";

export function useRamassageCancel() {
    const { successToast } = useToast();

    const mutation = useMutation({
        mutationFn: ramassageCancel,
        onSuccess: () => successToast("Le dossier a bien été réouvert"),
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ["folders"],
            });
        },
    });

    return mutation;
}
