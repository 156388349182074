import { FormProvider, useForm, useWatch } from "react-hook-form";

import { Stack, Typography } from "@mui/material";

import FormSearch from "../../../../components/form/FormSearch";
import { getFilteredTables } from "../../functions";
import { Table } from "../../types/grappage";
import GrappageAccordion from "./GrappageAccordion";

type Props = {
    tables: Table[];
};

function GrappageProductsList({ tables }: Props) {
    const form = useForm();
    const search = useWatch({ control: form.control, name: "search", defaultValue: "" });

    const filteredTables = getFilteredTables(tables || [], search);

    return (
        <Stack spacing={5}>
            <FormProvider {...form}>
                <form>
                    <FormSearch name="search" label="Rechercher" />
                </form>
            </FormProvider>

            <Stack spacing={2}>
                {!filteredTables.length ? (
                    <Typography>Aucun résultat</Typography>
                ) : (
                    filteredTables.map((table) => (
                        <GrappageAccordion key={table.id} table={table} />
                    ))
                )}
            </Stack>
        </Stack>
    );
}

export default GrappageProductsList;
