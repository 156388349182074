/* eslint-disable @typescript-eslint/naming-convention */
import {
    Divider,
    Link,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material";

import { RoadmapDetails } from "../types/roadmap-details";

type Props = {
    roadmap: RoadmapDetails;
    currentDay: number;
};

function RoadmapDay({ roadmap, currentDay }: Props) {
    const index = currentDay - 1;
    const infos = roadmap.jours_chantier[index];
    const { equipe_de_tir, hotel_info } = infos;

    const teamLeader = equipe_de_tir.find((user) => user.role === "Chef de tir");
    const artificers = equipe_de_tir.filter((user) => user.role === "Artificier");

    return (
        <Stack spacing={3}>
            <Stack spacing={2}>
                <Typography variant="h6">Equipe de tir</Typography>

                <Stack>
                    <Typography variant="subtitle1">Chef de tir</Typography>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        <Typography variant="subtitle2">{teamLeader?.nom}</Typography>
                        <Link sx={{ whiteSpace: "nowrap" }} href={`tel:${teamLeader?.telephone}`}>
                            {teamLeader?.telephone}
                        </Link>
                    </Stack>
                </Stack>
                <Stack>
                    <Typography variant="subtitle1">Artificiers</Typography>
                    {artificers.map((artificer) => (
                        <Stack
                            key={artificer.telephone}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                        >
                            <Typography variant="subtitle2">{artificer.nom}</Typography>
                            <Link sx={{ whiteSpace: "nowrap" }} href={`tel:${artificer.telephone}`}>
                                {artificer.telephone}
                            </Link>
                        </Stack>
                    ))}
                </Stack>
            </Stack>

            <Divider />

            <Stack spacing={2}>
                <Typography variant="h6">Hôtel</Typography>
                {hotel_info ? (
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell variant="head">Nom</TableCell>
                                <TableCell>{hotel_info[0].nom_hotel}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Téléphone</TableCell>
                                <TableCell>
                                    <Link href={`tel:${hotel_info[0].tel_hotel}`}>
                                        {hotel_info[0].tel_hotel}
                                    </Link>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Adresse</TableCell>
                                <TableCell>
                                    <Typography variant="body2">
                                        {hotel_info[0].adresse_hotel},
                                    </Typography>
                                    <Typography variant="body2">
                                        {hotel_info[0].cp_hotel} {hotel_info[0].ville_hotel}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Nbr. de personnes</TableCell>
                                <TableCell>{hotel_info[0].nbr_employes_hotel}</TableCell>
                            </TableRow>
                            <TableRow sx={{ verticalAlign: "top" }}>
                                <TableCell variant="head">Employés</TableCell>
                                <TableCell>
                                    {hotel_info[0].liste_employes.map((person) => (
                                        <Typography key={person} variant="body2">
                                            {person}
                                        </Typography>
                                    ))}
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ verticalAlign: "top" }}>
                                <TableCell variant="head">Infos hôtel</TableCell>
                                <TableCell>{hotel_info[0].infos_hotel}</TableCell>
                            </TableRow>
                            <TableRow
                                sx={{
                                    verticalAlign: "top",
                                    "&:last-child td": { border: 0 },
                                }}
                            >
                                <TableCell variant="head">Infos Particulières</TableCell>
                                <TableCell>{hotel_info[0].info_particuliere}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                ) : (
                    <Typography>
                        Pas de logement prévu ce jour là, retour au domicile prévu
                    </Typography>
                )}
            </Stack>
        </Stack>
    );
}

export default RoadmapDay;
