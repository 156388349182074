import { Container, Stack } from "@mui/material";

import LoadingSpinner from "../../components/LoadingSpinner";
import PageHeader from "../../components/PageHeader";
import { useGetRoadmaps } from "../../features/roadmap/api/useGetRoadmaps";
import RoadmapCard from "../../features/roadmap/components/RoadmapCard";

function RoadmapPage() {
    const roadmapsQuery = useGetRoadmaps();

    return (
        <>
            <PageHeader title="Feuilles de route" />
            <Container sx={{ py: 4 }} maxWidth="sm">
                {roadmapsQuery.isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <Stack spacing={3}>
                        {roadmapsQuery.data?.map((roadmap) => (
                            <RoadmapCard key={roadmap.id} roadmap={roadmap} />
                        ))}
                    </Stack>
                )}
            </Container>
        </>
    );
}

export default RoadmapPage;
