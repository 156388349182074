import { Container, Stack } from "@mui/material";

import LoadingSpinner from "../../components/LoadingSpinner";
import PageHeader from "../../components/PageHeader";
import { useGetContracts } from "../../features/contracts/api/useGetContracts";
import ContractCard from "../../features/contracts/components/ContractCard";
import { useAuthContext } from "../../features/auth/contexts/authContext";
import { ContractType } from "../../features/auth/types/contractType";

function ContractsPage() {
    const contractsQuery = useGetContracts();
    const { user } = useAuthContext();

    return (
        <>
            <PageHeader title="Gestion des contrats" />
            <Container sx={{ py: 4 }} maxWidth="sm">
                {contractsQuery.isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <Stack spacing={3}>
                        {contractsQuery.data?.map((contract) => (
                            <ContractCard
                                key={contract.contrat_id}
                                contract={contract}
                                showContractPdf={user?.admin_contrat_type !== ContractType.CDI}
                            />
                        ))}
                    </Stack>
                )}
            </Container>
        </>
    );
}

export default ContractsPage;
