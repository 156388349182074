/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Quagga from "quagga";
import { useEffect } from "react";

import { Box, Stack } from "@mui/material";

type Props = {
    onScan: (code: string) => void;
};

export default function Scanner({ onScan }: Props) {
    const startScanner = () => {
        Quagga.init(
            {
                inputStream: {
                    name: "Live",
                    type: "LiveStream",
                    target: document.querySelector("#scanner"),
                    constraints: {
                        facingMode: "environment", // or user
                    },
                    area: {
                        top: "0%",
                        right: "0%",
                        left: "0%",
                        bottom: "0%",
                    },
                },
                frequency: 10,
                decoder: {
                    readers: ["code_39_reader", "code_39_vin_reader"],
                    debug: {
                        showCanvas: false,
                        showPatches: false,
                        showFoundPatches: false,
                        showSkeleton: false,
                        showLabels: false,
                        showPatchLabels: false,
                        showRemainingPatchLabels: false,
                        boxFromPatches: {
                            showTransformed: false,
                            showTransformedBox: false,
                            showBB: false,
                        },
                    },
                    multiple: false,
                },
            },
            () => Quagga.start(),
        );

        Quagga.onProcessed((result: any) => {
            const drawingCtx = Quagga.canvas.ctx.overlay;
            const drawingCanvas = Quagga.canvas.dom.overlay;

            if (result) {
                if (result.boxes) {
                    drawingCtx.clearRect(
                        0,
                        0,
                        parseInt(drawingCanvas.getAttribute("width"), 10),
                        parseInt(drawingCanvas.getAttribute("height"), 10),
                    );
                    result.boxes
                        .filter((box: { box: any }) => box !== result.box)
                        .forEach((box: { box: any }) => {
                            Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
                                color: "#ffffff",
                                lineWidth: 2,
                            });
                        });
                }

                if (result.box) {
                    Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
                        color: "#ffffff",
                        lineWidth: 2,
                    });
                }

                if (result.codeResult && result.codeResult.code) {
                    Quagga.ImageDebug.drawPath(result.line, { x: "x", y: "y" }, drawingCtx, {
                        color: "#ffffff",
                        lineWidth: 3,
                    });
                }
            }
        });

        Quagga.onDetected((result: any) => {
            if (result.codeResult.code != null) {
                const scanner = document.querySelector("#scanner");
                scanner?.getElementsByTagName("video")[0].pause();
                Quagga.offDetected();
                Quagga.stop();
                onScan(result.codeResult.code);
            }
        });
    };

    useEffect(() => {
        startScanner();
        return () => {
            Quagga.offDetected();
            Quagga.stop();
        };
    }, []);

    return (
        <>
            <Stack
                sx={{
                    "& video": {
                        width: "100%",
                        maxWidth: 700,
                        objectFit: "contain",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    },
                    "& canvas": {
                        width: "100%",
                        height: "100%",
                        zIndex: 1,
                    },
                }}
                id="scanner"
                className="viewport"
            />
            <Box sx={{ height: 0 }} className="scanArea__freezedFrame" />
        </>
    );
}
