import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Stack } from "@mui/material";

import FormRadio from "../../../components/form/FormRadio";
import { useCreateWorkSiteReport } from "../api/useCreateWorkSiteReport";
import { WorkSiteReportFormData } from "../types/work-site-report-form";
import workSiteReportSchema from "../validation/workSiteReportSchema";
import ShotIsFiredForm from "./ShotIsFiredForm";
import ShotIsNotFiredForm from "./ShotIsNotFiredForm";

function ReportForm() {
    const { id } = useParams();

    const form = useForm<WorkSiteReportFormData>({
        shouldUnregister: true,
        resolver: yupResolver(workSiteReportSchema, { stripUnknown: true }),
    });

    const createReport = useCreateWorkSiteReport(id as string);
    const onSubmit = (formData: WorkSiteReportFormData) => {
        createReport.mutate(formData);
    };
    const shotIsFired = useWatch({ control: form.control, name: "effectue" });

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
                <Stack spacing={3}>
                    <FormRadio
                        row
                        name="effectue"
                        label="Le tir a été effectué ?"
                        options={[
                            { value: "1", label: "Oui" },
                            { value: "0", label: "Non" },
                        ]}
                        boolean
                    />

                    {shotIsFired === true ? <ShotIsFiredForm /> : null}

                    {shotIsFired === false ? <ShotIsNotFiredForm /> : null}

                    <Button type="submit" fullWidth variant="contained">
                        Envoyer le rapport
                    </Button>
                </Stack>
            </form>
        </FormProvider>
    );
}

export default ReportForm;
