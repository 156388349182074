import { useQuery } from "@tanstack/react-query";

import { Grappage } from "../types/grappage";
import { getGrappageProducts } from "./actions";

type GrappageProductsOptions = {
    onSuccess?: (data: Grappage) => void;
};

export function useGetGrappageProducts(folderId: string, options: GrappageProductsOptions) {
    const query = useQuery({
        queryKey: ["folders", "grappage", folderId],
        queryFn: () => getGrappageProducts(folderId),
        staleTime: 0,
        ...options,
    });

    return query;
}
