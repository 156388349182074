import { useParams } from "react-router-dom";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material";

import { usePostRamassageProduct } from "../../api/usePostRamassageProduct";
import { Product } from "../../types/product";

type Props = {
    product: Product;
    open: boolean;
    onClose: () => void;
};

function CancelDialog({ open, onClose, product }: Props) {
    const { folderId } = useParams();
    const ramassageQuery = usePostRamassageProduct();

    const handleOnConfirm = () => {
        const request = { dossier_id: folderId, produit_id: product.id, quantity: "0" };
        ramassageQuery.mutate(request, { onSuccess: onClose });
    };

    return (
        <Dialog open={open} disableRestoreFocus>
            <DialogTitle>Annuler ramassage</DialogTitle>
            <DialogContent>
                <Typography>Souhaitez-vous annuler le ramassage de ce produit ?</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Fermer</Button>
                <Button onClick={handleOnConfirm}>Confirmer</Button>
            </DialogActions>
        </Dialog>
    );
}

export default CancelDialog;
