import { useQuery } from "@tanstack/react-query";

import { getTransportInfos } from "./actions";

export function useGetTransportInfos(folderId: string) {
    const query = useQuery({
        queryKey: ["transport-infos", folderId],
        queryFn: () => getTransportInfos(folderId),
        staleTime: 0,
    });

    return query;
}
