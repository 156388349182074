import { AxiosRequestConfig } from "axios";

import { useMutation } from "@tanstack/react-query";

import { queryClient } from "../../../config/react-query";
import { useToast } from "../../../hooks/useToast";
import { CostFormData } from "../types/cost-form";
import { createCost } from "./actions";
import { QUERY_KEY } from "./config";

export function useCreateCost(config?: AxiosRequestConfig) {
    const { successToast } = useToast();

    const mutation = useMutation({
        mutationFn: (request: CostFormData) => createCost(request, config),
        onSuccess: (_data, variables: CostFormData) => {
            successToast("Votre frais a été enregistré");
            queryClient.invalidateQueries({ queryKey: [QUERY_KEY, variables.contrat_id, "costs"] });
        },
    });

    return mutation;
}
