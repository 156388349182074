export const hideKeyboard = (el: EventTarget & (HTMLInputElement | HTMLTextAreaElement)) => {
    const att = document.createAttribute("readonly");
    el.setAttributeNode(att); // Force keyboard to hide on input field.
    setTimeout(() => {
        el.blur(); // close the keyboard
        el.focus(); // focus on the input
        // Remove readonly attribute after keyboard is hidden.
        el.removeAttribute("readonly");
    }, 100);
};
