import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Stack, Typography } from "@mui/material";

import FormSearch from "../../../components/form/FormSearch";
import datetime from "../../../config/datetime";
import { isEmpty } from "../../../utils/object";
import { getFilteredFolders, getFoldersByDate } from "../functions";
import FolderItem from "./ramassage/FolderItem";

import type { Folder } from "../types/folder";

type Props = {
    folders: Folder[];
};

function FoldersList({ folders }: Props) {
    const navigate = useNavigate();
    const form = useForm();
    const search = useWatch({ control: form.control, name: "search", defaultValue: "" });

    const filteredFolders = getFilteredFolders(folders, search);
    const foldersByDate = getFoldersByDate(filteredFolders);

    return (
        <Stack spacing={5}>
            <FormProvider {...form}>
                <form>
                    <FormSearch name="search" label="Rechercher" />
                </form>
            </FormProvider>

            {isEmpty(foldersByDate) ? (
                <Typography>Aucun dossier n'a été trouvé</Typography>
            ) : (
                Object.entries(foldersByDate).map(([date, results]) => (
                    <Stack spacing={1} key={date}>
                        <Typography variant="subtitle2">{datetime(date).format("LL")}</Typography>
                        {results.map((folder) => (
                            <FolderItem
                                key={folder.id}
                                folder={folder}
                                onClick={() => navigate(folder.id)}
                            />
                        ))}
                    </Stack>
                ))
            )}
        </Stack>
    );
}

export default FoldersList;
