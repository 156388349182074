import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { Button, Stack, Typography, useTheme } from "@mui/material";

type Props = {
    currentDay: number;
    steps: number;
    onClickBack: () => void;
    onClickNext: () => void;
};

function DayStepper({ currentDay, steps, onClickBack, onClickNext }: Props) {
    const { palette } = useTheme();

    return (
        <Stack
            direction="row"
            sx={{
                position: "sticky",
                top: 0,
                py: 1,
                backgroundColor: palette.background.default,
                alignItems: "center",
                justifyContent: "space-between",
                zIndex: 1,
            }}
        >
            <Button
                sx={{ opacity: currentDay === 1 ? 0 : 1 }}
                disabled={currentDay === 1}
                startIcon={<ChevronLeftRoundedIcon />}
                onClick={onClickBack}
            >
                Jour {currentDay - 1}
            </Button>
            <Typography>Jour {currentDay}</Typography>
            <Button
                sx={{ opacity: currentDay === steps ? 0 : 1 }}
                disabled={currentDay === steps}
                onClick={onClickNext}
                endIcon={<ChevronRightRoundedIcon />}
            >
                Jour {currentDay + 1}
            </Button>
        </Stack>
    );
}

export default DayStepper;
