import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { Button } from "@mui/material";

import Accordion from "../../../../components/Accordion";
import { Table } from "../../types/grappage";
import GrappageLineAccordion from "./GrappageLineAccordion";

type Props = {
    table: Table;
};

function GrappageAccordion({ table }: Props) {
    const icon = table.tableau_ok ? (
        <Button
            color="success"
            sx={{
                pointerEvents: "none",
                minWidth: 0,
                borderRadius: 50,
                width: 40,
                height: 40,
            }}
            variant="outlined"
        >
            <CheckRoundedIcon />
        </Button>
    ) : null;

    return (
        <Accordion title={table.nom} icon={icon}>
            {table.lignes.map((line) => (
                <GrappageLineAccordion key={line.id} line={line} />
            ))}
        </Accordion>
    );
}

export default GrappageAccordion;
