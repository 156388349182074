import { ButtonBase, Paper, Stack, Typography, useTheme } from "@mui/material";

type Props = {
    name: string;
    weight: number;
    onClick: () => void;
    isError?: boolean;
};

function DepotButton({ name, weight, onClick, isError = false }: Props) {
    const { palette } = useTheme();

    const color = isError ? palette.error.main : undefined;

    return (
        <Paper
            sx={{
                borderRadius: 1,
                border: isError ? `1px solid ${color}` : null,
            }}
        >
            <ButtonBase sx={{ p: 2, width: "100%" }} onClick={onClick}>
                <Stack>
                    <Typography variant="button">{name}</Typography>
                    <Typography variant="body2" color={color}>
                        {weight} kg
                    </Typography>
                </Stack>
            </ButtonBase>
        </Paper>
    );
}

export default DepotButton;
