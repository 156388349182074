import { useState } from "react";
import { Controller } from "react-hook-form";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment, TextField } from "@mui/material";

type Props = {
    name: string;
    label: string;
    defaultValue?: string;
};

function FormPassword({ name, label, defaultValue = "" }: Props) {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    return (
        <Controller
            name={name}
            defaultValue={defaultValue}
            render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
                <TextField
                    inputRef={ref}
                    onChange={onChange}
                    value={value}
                    fullWidth
                    id={name}
                    label={label}
                    variant="standard"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    error={!!error}
                    helperText={error ? error.message : null}
                />
            )}
        />
    );
}

export default FormPassword;
