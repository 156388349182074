import { useContext } from "react";

import { AuthContext } from "./AuthContext";

export const useAuthContext = () => {
    const context = useContext(AuthContext);

    if (context === undefined) {
        throw new Error("useAuthContext est utilisé en dehors de son provider");
    }

    return context;
};
