import { number, object } from "yup";

const transportInfosSchema = object().shape({
    contrat_nb_cartons_13G: number()
        .required("Le nombre de cartons est requis")
        .min(0, ({ min }) => `Le nombre de cartons doit être supérieur ou égal à ${min}`),
    contrat_poids_13G: number()
        .required("Le poids est requis")
        .min(0, ({ min }) => `Le poids doit être supérieur ou égal à ${min}`),
    contrat_nb_cartons_14G: number()
        .required("Le nombre de cartons est requis")
        .min(0, ({ min }) => `Le nombre de cartons doit être supérieur ou égal à ${min}`),
    contrat_poids_14G: number()
        .required("Le poids est requis")
        .min(0, ({ min }) => `Le poids doit être supérieur ou égal à ${min}`),
    contrat_nb_cartons_14S: number()
        .required("Le nombre de cartons est requis")
        .min(0, ({ min }) => `Le nombre de cartons doit être supérieur ou égal à ${min}`),
    contrat_poids_14S: number()
        .required("Le poids est requis")
        .min(0, ({ min }) => `Le poids doit être supérieur ou égal à ${min}`),
});

export default transportInfosSchema;
