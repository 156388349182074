import "dayjs/locale/fr";

import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import timezone from "dayjs/plugin/timezone";

const datetime = dayjs;

datetime.extend(timezone);
datetime.extend(localizedFormat);
datetime.locale("fr");

datetime.tz.setDefault("Europe/Paris");

export default datetime;
