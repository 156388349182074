function UnauthorizedPage() {
    return (
        <div>
            <h1>ERREUR 401</h1>
            <p>Vous n'êtes pas autorisé</p>
        </div>
    );
}

export default UnauthorizedPage;
