import { useState } from "react";

import CheckroomRoundedIcon from "@mui/icons-material/CheckroomRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PhoneIphoneRoundedIcon from "@mui/icons-material/PhoneIphoneRounded";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Stack,
    Typography,
} from "@mui/material";

import LoadingSpinner from "../../components/LoadingSpinner";
import PageHeader from "../../components/PageHeader";
import { useGetUser } from "../../features/profile/api/useGetUser";
import EditProfileForm from "../../features/profile/EditProfileForm";

function ProfilePage() {
    const [open, setOpen] = useState(false);

    const { data: user, isLoading } = useGetUser();

    const INFOS = [
        {
            primary: user?.tel,
            secondary: "Téléphone",
            icon: <PhoneIphoneRoundedIcon />,
        },
        {
            primary: user?.email,
            secondary: "E-mail",
            icon: <EmailRoundedIcon />,
        },
        {
            primary: `${user?.adresse.adresse} - ${user?.adresse.cp} ${user?.adresse.ville}`,
            secondary: "Adresse",
            icon: <HomeRoundedIcon />,
        },
        {
            primary: user?.taille,
            secondary: "Taille de t-shirt",
            icon: <CheckroomRoundedIcon />,
        },
    ];

    const handleOpenDiploma = () => {
        window.open(user?.diplome, "_blank");
    };

    return (
        <>
            <PageHeader title="Profil" />
            <Container sx={{ py: 4 }} maxWidth="sm">
                {isLoading || !user ? (
                    <LoadingSpinner />
                ) : (
                    <Stack spacing={3}>
                        <Paper elevation={1}>
                            <Stack sx={{ py: 3, px: 2 }} spacing={2}>
                                <Typography variant="h5" fontWeight="bold" textAlign="center">
                                    {user.prenom} {user.nom}
                                </Typography>
                                {user.diplome ? (
                                    <Button
                                        variant="contained"
                                        startIcon={<SchoolRoundedIcon />}
                                        fullWidth
                                        onClick={handleOpenDiploma}
                                    >
                                        Consulter mon diplôme
                                    </Button>
                                ) : (
                                    <Alert severity="warning" icon={<SchoolRoundedIcon />}>
                                        <AlertTitle>
                                            Vous n'êtes pas détenteur d'un diplôme artificier
                                        </AlertTitle>
                                        Pour obtenir votre diplôme, veuillez vous rapprocher du
                                        service gestion de la société.
                                    </Alert>
                                )}
                            </Stack>
                        </Paper>
                        <Box>
                            <List>
                                {INFOS.map(({ primary, secondary, icon }) => (
                                    <ListItem key={secondary}>
                                        <ListItemIcon>{icon}</ListItemIcon>
                                        <ListItemText primary={primary} secondary={secondary} />
                                    </ListItem>
                                ))}
                            </List>
                            <Stack alignItems="flex-end">
                                <Button
                                    endIcon={<KeyboardArrowRightIcon />}
                                    onClick={() => setOpen(true)}
                                >
                                    Signaler un changement
                                </Button>
                            </Stack>
                        </Box>
                    </Stack>
                )}
            </Container>

            <Dialog maxWidth="xs" fullWidth open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Signaler un changement</DialogTitle>
                <DialogContent dividers>
                    <EditProfileForm setOpen={setOpen} />
                </DialogContent>
            </Dialog>
        </>
    );
}

export default ProfilePage;
