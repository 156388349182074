import { useNavigate, useParams } from "react-router-dom";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material";

import { useRamassageCancel } from "../../api/useRamassageCancel";

type Props = {
    open: boolean;
    onClose: () => void;
};

function CancelRamassageDialog({ open, onClose }: Props) {
    const navigate = useNavigate();
    const { folderId } = useParams();

    const ramassageCancelQuery = useRamassageCancel();
    const handleOnConfirm = () => {
        ramassageCancelQuery.mutate(folderId as string, { onSuccess: onClose });
    };
    return (
        <Dialog open={open} disableRestoreFocus>
            <DialogTitle>Réouvrir ce dossier</DialogTitle>
            <DialogContent>
                <Typography>
                    Le ramassage de ce dossier est terminé, souhaitez-vous le réouvrir ?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => navigate("/ramassage")}>Retour</Button>
                <Button onClick={handleOnConfirm}>Confirmer</Button>
            </DialogActions>
        </Dialog>
    );
}

export default CancelRamassageDialog;
