import apiClient from "../../../config/api-client";
import { mergeWorkSites } from "../functions";
import { WorkSite } from "../types/work-site";
import { WorkSiteReportFormData } from "../types/work-site-report-form";
import { ENDPOINT } from "./config";

export async function getWorkSites(): Promise<WorkSite[]> {
    const response = await apiClient.post(ENDPOINT, {
        classe: "tf_dossier",
        methode: "get_all_dossier",
        params: [],
    });

    return mergeWorkSites(response.data);
}

export async function createWorkSiteReport(
    workSiteId: string,
    request: WorkSiteReportFormData,
): Promise<string> {
    const response = await apiClient.post(ENDPOINT, {
        classe: "tf_rapport_tir",
        methode: "form_rapport_de_tir",
        params: [workSiteId, request],
    });

    return response.data;
}
