import { useMutation, useQueryClient } from "@tanstack/react-query";
import { confirmRoadmap } from "./actions";
import { QUERY_KEY } from "./config";
import { useToast } from "../../../hooks";

export function useConfirmRoadmap() {
    const queryClient = useQueryClient();
    const { successToast } = useToast();

    const query = useMutation({
        mutationFn: confirmRoadmap,
        onSuccess: () => {
            successToast("Organisation du chantier confirmée");
            queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
        },
    });

    return query;
}
