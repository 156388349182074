import { AxiosProgressEvent } from "axios";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { InputAdornment, Stack } from "@mui/material";

import CircularProgressWithLabel from "../../../components/CircularProgressWithLabel";
import FormFile from "../../../components/form/FormFile";
import FormNumber from "../../../components/form/FormNumber";
import FormSelect from "../../../components/form/FormSelect";
import { useCreateCost } from "../api/useCreateCost";
import { Contract } from "../types/contract";
import { CostFormData } from "../types/cost-form";
import { CostType } from "../types/cost-type";
import costSchema from "../validation/costSchema";

type Props = {
    contract: Contract;
    onSuccess: () => void;
};

function CostForm({ contract, onSuccess }: Props) {
    const { contractId } = useParams();
    const [uploadProgress, setUploadProgress] = useState(0);
    const form = useForm<CostFormData>({ resolver: yupResolver(costSchema) });

    const onUploadProgress = (ProgressEvent: AxiosProgressEvent) => {
        if (!ProgressEvent.total) return;
        setUploadProgress(Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total));
    };
    const costMutation = useCreateCost({ onUploadProgress });

    const onSubmit = (formData: CostFormData) => {
        const request = { contrat_id: contractId, ...formData };
        costMutation.mutate(request, { onSuccess });
    };

    const uniqueWorkSites = Object.values(
        contract.chantiers.reduce(
            (acc: Record<string, (typeof contract.chantiers)[number]>, obj) => {
                acc[obj.dossier_id] = obj;
                return acc;
            },
            {},
        ),
    );

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
                <Stack spacing={3}>
                    {costMutation.isLoading || costMutation.isSuccess ? (
                        <Stack justifyContent="center" alignItems="center">
                            <CircularProgressWithLabel
                                size="6rem"
                                thickness={2}
                                value={uploadProgress}
                            />
                        </Stack>
                    ) : (
                        <>
                            <FormSelect
                                name="dossier_id"
                                label="Chantier"
                                options={uniqueWorkSites.map((worksite) => ({
                                    value: worksite.dossier_id,
                                    label: worksite.nom_chantier,
                                }))}
                            />
                            <FormSelect
                                name="lier_contrat_frais_type"
                                label="Type de frais"
                                options={Object.keys(CostType).map((key) => ({
                                    value: CostType[key as keyof typeof CostType],
                                    label: key,
                                }))}
                            />
                            <FormNumber
                                name="lier_contrat_frais_montant"
                                label="Montant"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">€</InputAdornment>
                                    ),
                                    inputProps: { min: 0.01 },
                                }}
                            />

                            <FormFile name="ticket" placeholder="Ticket de caisse" />
                        </>
                    )}

                    <LoadingButton
                        loading={costMutation.isLoading || costMutation.isSuccess}
                        type="submit"
                        fullWidth
                        variant="contained"
                    >
                        Valider
                    </LoadingButton>
                </Stack>
            </form>
        </FormProvider>
    );
}

export default CostForm;
