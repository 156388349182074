import { useQuery } from "@tanstack/react-query";

import { getRoadmap } from "./actions";
import { QUERY_KEY } from "./config";

export function useGetRoadmap(id: string) {
    const query = useQuery({
        queryKey: [QUERY_KEY, id],
        queryFn: () => getRoadmap(id),
        staleTime: 5000,
    });

    return query;
}
