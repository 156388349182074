import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment,
} from "@mui/material";

import FormNumber from "../../../../components/form/FormNumber";
import { usePostRamassageProduct } from "../../api/usePostRamassageProduct";
import { Product } from "../../types/product";
import { RamassageFormData } from "../../types/ramassage-form";
import getQuantitySchema from "../../validation/quantitySchema";

type Props = {
    product: Product;
    open: boolean;
    onClose: () => void;
};

function QuantityDialog({ open, onClose, product }: Props) {
    const { folderId } = useParams();
    const ramassageQuery = usePostRamassageProduct();

    const form = useForm<RamassageFormData>({
        resolver: yupResolver(getQuantitySchema(parseInt(product.quantite, 10))),
    });
    useEffect(() => {
        setTimeout(() => form.setFocus("quantity"), 100);
    }, [open, form]);

    const onSubmit = (formData: RamassageFormData) => {
        const request = { ...formData, dossier_id: folderId, produit_id: product.id };
        ramassageQuery.mutate(request, { onSuccess: onClose });
    };

    return (
        <Dialog open={open} disableRestoreFocus>
            <DialogTitle>Quelle quantité avez-vous prévelée ?</DialogTitle>
            <DialogContent>
                <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        <FormNumber
                            name="quantity"
                            label="Quantité"
                            defaultValue={parseInt(product.quantite_ramassee, 10)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        / {product.quantite}
                                    </InputAdornment>
                                ),
                                inputProps: { min: 0, max: product.quantite },
                            }}
                            selectOnFocus
                        />
                    </form>
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Annuler</Button>
                <Button onClick={form.handleSubmit(onSubmit)}>Valider</Button>
            </DialogActions>
        </Dialog>
    );
}

export default QuantityDialog;
