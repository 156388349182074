import { number, object } from "yup";

const getQuantitySchema = (max: number) =>
    object().shape({
        quantity: number()
            .required("Ce champ est requis")
            .min(0, "La quantité doit être supérieure ou égale à 0")
            .max(max, `La quantité doit être inférieure ou égale à ${max}`),
    });

export default getQuantitySchema;
