import apiClient from "../../../config/api-client";
import { getAdminId } from "../../../utils/jwt";
import { Folder } from "../types/folder";
import { Grappage } from "../types/grappage";
import { Ramassage } from "../types/ramassage";
import { RamassageFormData } from "../types/ramassage-form";
import { Stockage } from "../types/stockage";
import { StockageFormData } from "../types/stockage-form";
import { TransportInfosFormData } from "../types/transport-infos-form";

const { VITE_API_BASE_URL_STOCK } = import.meta.env;

export async function getFolders(foldersType: string): Promise<Folder[]> {
    const { data } = await apiClient.get(`action=dossier_${foldersType}`, {
        baseURL: VITE_API_BASE_URL_STOCK,
    });

    return data?.dossier || [];
}

// Ramassage
export async function getRamassageProducts(folderId: string): Promise<Ramassage> {
    const { data } = await apiClient.get(`action=produit_a_ramasser&dossier_id=${folderId}`, {
        baseURL: VITE_API_BASE_URL_STOCK,
    });

    return data;
}

export async function ramasserProduct(request: RamassageFormData): Promise<object> {
    const adminId = getAdminId();

    const { data } = await apiClient.get(
        `action=ramasser&dossier_id=${request.dossier_id}&produit_id=${request.produit_id}&quantite=${request.quantity}&admin_id=${adminId}`,
        {
            baseURL: VITE_API_BASE_URL_STOCK,
        },
    );

    return data;
}

export async function ramassageValidate(folderId: string): Promise<object> {
    const adminId = getAdminId();

    const { data } = await apiClient.get(
        `action=valider_ramasser&dossier_id=${folderId}&admin_id=${adminId}`,
        {
            baseURL: VITE_API_BASE_URL_STOCK,
        },
    );

    return data;
}

export async function ramassageCancel(folderId: string): Promise<object> {
    const adminId = getAdminId();

    const { data } = await apiClient.get(
        `action=annuler_ramasser&dossier_id=${folderId}&admin_id=${adminId}`,
        {
            baseURL: VITE_API_BASE_URL_STOCK,
        },
    );

    return data;
}

// Grappage
export async function getGrappageProducts(folderId: string): Promise<Grappage> {
    const { data } = await apiClient.get(`action=produit_a_grapper&dossier_id=${folderId}`, {
        baseURL: VITE_API_BASE_URL_STOCK,
    });

    return data;
}

export async function grappageValidate(folderId: string): Promise<object> {
    const adminId = getAdminId();

    const { data } = await apiClient.get(
        `action=valider_grapper&dossier_id=${folderId}&admin_id=${adminId}`,
        {
            baseURL: VITE_API_BASE_URL_STOCK,
        },
    );

    return data;
}

export async function grappageLine(params: { lineId: string; grapper: 0 | 1 }): Promise<object> {
    const adminId = getAdminId();

    const { data } = await apiClient.get(
        `action=grapper&ligne_id=${params.lineId}&grapper=${params.grapper}&admin_id=${adminId}`,
        {
            baseURL: VITE_API_BASE_URL_STOCK,
        },
    );

    return data;
}

export async function cartonLine(params: { lineId: string; carton: 0 | 1 }): Promise<object> {
    const adminId = getAdminId();

    const { data } = await apiClient.get(
        `action=carton&ligne_id=${params.lineId}&carton=${params.carton}&admin_id=${adminId}`,
        {
            baseURL: VITE_API_BASE_URL_STOCK,
        },
    );

    return data;
}

export async function grappageCancel(folderId: string): Promise<object> {
    const adminId = getAdminId();

    const { data } = await apiClient.get(
        `action=annuler_grapper&dossier_id=${folderId}&admin_id=${adminId}`,
        {
            baseURL: VITE_API_BASE_URL_STOCK,
        },
    );

    return data;
}

// Transport
export async function getTransportInfos(folderId: string): Promise<TransportInfosFormData> {
    const { data } = await apiClient.get(`action=info_transport&dossier_id=${folderId}`, {
        baseURL: VITE_API_BASE_URL_STOCK,
    });

    return data;
}

export async function postTransportInfos(request: TransportInfosFormData): Promise<object> {
    const adminId = getAdminId();
    const { folderId, ...formData } = request;
    const params = new URLSearchParams(formData).toString();

    const { data } = await apiClient.get(
        `action=maj_info_transport&dossier_id=${folderId}&admin_id=${adminId}&${params}`,
        {
            baseURL: VITE_API_BASE_URL_STOCK,
        },
    );

    return data;
}

// Stockage
export async function getStockageInfos(folderId: string): Promise<Stockage> {
    const { data } = await apiClient.get(`action=liste_depot&dossier_id=${folderId}`, {
        baseURL: VITE_API_BASE_URL_STOCK,
    });

    return data;
}

export async function stockFolder(request: StockageFormData): Promise<object> {
    const adminId = getAdminId();

    const { depotId, folderId } = request;

    const { data } = await apiClient.get(
        `action=stocker&dossier_id=${folderId}&depot=${depotId}&admin_id=${adminId}`,
        {
            baseURL: VITE_API_BASE_URL_STOCK,
        },
    );

    return data;
}

export async function stockageCancel(folderId: string): Promise<object> {
    const adminId = getAdminId();

    const { data } = await apiClient.get(
        `action=annuler_stocker&dossier_id=${folderId}&admin_id=${adminId}`,
        {
            baseURL: VITE_API_BASE_URL_STOCK,
        },
    );

    return data;
}

export async function fireFolder(folderId: string): Promise<object> {
    const adminId = getAdminId();

    const { data } = await apiClient.get(
        `action=tirer&dossier_id=${folderId}&admin_id=${adminId}`,
        {
            baseURL: VITE_API_BASE_URL_STOCK,
        },
    );

    return data;
}
