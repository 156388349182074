import { useState } from "react";
import { useNavigate } from "react-router-dom";

import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PictureAsPdfRoundedIcon from "@mui/icons-material/PictureAsPdfRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import datetime from "../../../config/datetime";

import type { Roadmap } from "../types/roadmap";
import { useConfirmRoadmap } from "../api/useConfirmRoadmap";

type Props = {
    roadmap: Roadmap;
};

function RoadmapCard({ roadmap }: Props) {
    const { palette } = useTheme();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleClickConfirmation = () => {
        setOpen(true);
    };

    const confirmRoadmapMutation = useConfirmRoadmap();
    const handleConfirm = () => {
        confirmRoadmapMutation.mutate(roadmap.id, { onSuccess: () => setOpen(false) });
    };

    return (
        <>
            <Card>
                <CardContent>
                    <Typography variant="h5" fontWeight="bold">
                        {roadmap.chantier}
                    </Typography>
                    <List dense>
                        <ListItem disablePadding>
                            <ListItemText primary={datetime(roadmap.date).format("LL")} />
                        </ListItem>
                    </List>
                </CardContent>
                <Divider />
                <CardActions sx={{ px: 0 }}>
                    <List dense sx={{ width: "100%" }}>
                        <ListItem
                            disablePadding
                            secondaryAction={
                                <IconButton onClick={() => navigate(`${roadmap.id}`)}>
                                    <KeyboardArrowRightIcon fontSize="small" />
                                </IconButton>
                            }
                        >
                            <ListItemButton onClick={() => navigate(`${roadmap.id}`)}>
                                <ListItemIcon>
                                    <VisibilityRoundedIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Consulter" />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton onClick={() => window.open(roadmap.pdf, "_blank")}>
                                <ListItemIcon>
                                    <PictureAsPdfRoundedIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Télécharger" />
                            </ListItemButton>
                        </ListItem>

                        {typeof roadmap.confirmation === "boolean" ? (
                            <ListItem
                                disablePadding
                                secondaryAction={
                                    roadmap.confirmation ? (
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            gap={1}
                                            paddingRight={1}
                                        >
                                            <Typography
                                                color={palette.success.main}
                                                variant="button"
                                            >
                                                Confirmé
                                            </Typography>
                                            <CheckRoundedIcon color="success" fontSize="small" />
                                        </Stack>
                                    ) : (
                                        <Button
                                            onClick={handleClickConfirmation}
                                            endIcon={<KeyboardArrowRightIcon fontSize="small" />}
                                        >
                                            À confirmer
                                        </Button>
                                    )
                                }
                            >
                                <ListItemButton
                                    disabled={roadmap.confirmation}
                                    onClick={handleClickConfirmation}
                                >
                                    <ListItemIcon>
                                        <GroupsRoundedIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{ color: "primary" }}
                                        primary="Organisation"
                                    />
                                </ListItemButton>
                            </ListItem>
                        ) : null}
                    </List>
                </CardActions>
            </Card>

            <Dialog maxWidth="xs" open={open}>
                <DialogTitle>Organisation du chef de tir</DialogTitle>
                <DialogContent dividers>
                    <Typography>
                        En cliquant sur "CONFIRMER", je valide avoir organisé le chantier avec mon
                        équipe
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={confirmRoadmapMutation.isLoading}
                        autoFocus
                        onClick={() => setOpen(false)}
                    >
                        Annuler
                    </Button>
                    <LoadingButton
                        loading={confirmRoadmapMutation.isLoading}
                        onClick={handleConfirm}
                    >
                        CONFIRMER
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default RoadmapCard;
