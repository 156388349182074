import { AxiosError } from "axios";

import { useMutation } from "@tanstack/react-query";

import { ForgotPasswordFormData } from "../types/forgot-password-form";
import { forgotPassword } from "./actions";

export function useForgotPassword() {
    const mutation = useMutation<string, AxiosError, ForgotPasswordFormData>({
        mutationFn: forgotPassword,
    });

    return mutation;
}
