import { useNavigate, useParams } from "react-router-dom";

import { Button, Paper, Stack, Typography } from "@mui/material";

import { useStockCancel } from "../../api/useStockCancel";
import { useStockFire } from "../../api/useStockFire";

type Props = {
    depotNumber: string;
};

function DepotItem({ depotNumber }: Props) {
    const { folderId } = useParams();
    const navigation = useNavigate();

    const stockCancelMutation = useStockCancel();
    const handleCancel = () => stockCancelMutation.mutate(folderId as string);

    const fireMutation = useStockFire();
    const handleFire = () => {
        fireMutation.mutate(folderId as string, { onSuccess: () => navigation("/") });
    };

    return (
        <Paper sx={{ p: 3 }}>
            <Stack spacing={2}>
                <Stack>
                    <Typography>Ce dossier est stocké dans le</Typography>
                    <Typography fontWeight="bold">dépôt {depotNumber}</Typography>
                </Stack>

                <Button fullWidth variant="contained" color="error" onClick={handleCancel}>
                    Annuler
                </Button>
                <Button fullWidth variant="contained" onClick={handleFire}>
                    Charger en camion
                </Button>
            </Stack>
        </Paper>
    );
}

export default DepotItem;
