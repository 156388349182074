import { AxiosError } from "axios";
import { FormProvider, useForm, useFormState } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, Stack } from "@mui/material";

import jwt_decode from "jwt-decode";
import FormPassword from "../../components/form/FormPassword";
import FormText from "../../components/form/FormText";
import { useLogin } from "./api/useLogin";
import { useAuthContext } from "./contexts/authContext";
import loginSchema from "./validation/loginSchema";

import type { LoginFormData } from "./types/login-form";
import { Jwt } from "./types/jwt";
import { ContractType } from "./types/contractType";

function LoginForm() {
    const { login } = useAuthContext();
    const navigate = useNavigate();

    const form = useForm<LoginFormData>({ resolver: yupResolver(loginSchema) });
    const { errors } = useFormState({ control: form.control });

    const handleSuccess = (token: string) => {
        login(token);
        const decoded: Jwt = jwt_decode(token);
        const { user } = decoded.data.data;
        if (user.admin_contrat_type === ContractType.CDI) {
            navigate("/");
            return;
        }
        navigate("/dashboard");
    };

    const handleError = (error: AxiosError) => {
        if (error.response?.status === 401) {
            form.setError("root.server", {
                type: "server",
                message: "Email ou mot de passe incorrect",
            });
            return undefined;
        }
        throw error;
    };

    const loginMutation = useLogin();
    const onSubmit = (formData: LoginFormData) => {
        loginMutation.mutate(formData, { onSuccess: handleSuccess, onError: handleError });
    };

    return (
        <FormProvider {...form}>
            <Stack spacing={5}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <Stack spacing={5} justifyContent="center" alignItems="center">
                        <FormText name="email" label="Email" />

                        <Stack width="100%" alignItems="flex-end">
                            <FormPassword name="password" label="Mot de passe" />
                            <Button onClick={() => navigate("/forgot-password")} sx={{ px: 0 }}>
                                Mot de passe oublié ?
                            </Button>
                        </Stack>

                        <Button type="submit" fullWidth variant="contained">
                            Connexion
                        </Button>
                    </Stack>
                </form>
                {loginMutation.isError && errors.root ? (
                    <Alert severity="error">{errors.root.server.message}</Alert>
                ) : null}
            </Stack>
        </FormProvider>
    );
}

export default LoginForm;
