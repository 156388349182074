import { mixed, number, object, string } from "yup";

import { CostType } from "../types/cost-type";

const costSchema = object().shape({
    dossier_id: string()
        .matches(/^\d+$/, "Cette valeur n'est pas valide")
        .required("Le champ est requis"),
    lier_contrat_frais_type: mixed<CostType>()
        .oneOf(Object.values(CostType), "Cette valeur n'est pas valide")
        .required("Le champ est requis"),
    lier_contrat_frais_montant: number()
        .required("Ce champ est requis")
        .moreThan(0, "Le montant doit être supérieur à 0"),
    ticket: mixed<File>()
        .required("La photo du ticket est requise")
        .test(
            "fileFormat",
            "Le format de l'image doit être JPEG, PNG ou GIF",
            (value: File) => value && ["image/jpeg", "image/png", "image/gif"].includes(value.type),
        )
        .test(
            "fileSize",
            "La taille de l'image ne doit pas dépasser 5 Mo",
            (value) => value && value.size <= 5242880,
        ),
});

export default costSchema;
