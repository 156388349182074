import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { useTheme } from "@mui/material";

import Accordion from "../../../../components/Accordion";
import { GrappageLine } from "../../types/grappage";
import GrappageLineItem from "./ProductItemGrappage";

type Props = {
    line: GrappageLine;
};

function GrappageLineAccordion({ line }: Props) {
    const { palette } = useTheme();

    const isPickedUp = line.carton === "1";
    const icon = isPickedUp ? <CheckRoundedIcon color="success" /> : null;

    return (
        <Accordion
            title={`ligne ${line.numero}`}
            icon={icon}
            sx={{ background: palette.background.default }}
        >
            <GrappageLineItem key={line.id} line={line} />
        </Accordion>
    );
}

export default GrappageLineAccordion;
