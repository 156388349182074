import { useMutation } from "@tanstack/react-query";

import { queryClient } from "../../../config/react-query";
import { useToast } from "../../../hooks/useToast";
import { postTransportInfos } from "./actions";

export function usePostTransportInfos() {
    const { successToast } = useToast();

    const mutation = useMutation({
        mutationFn: postTransportInfos,
        onSuccess: () => successToast("Informations de transport mises à jour"),
        onSettled: (_data, _error, formData) => {
            queryClient.invalidateQueries({
                queryKey: ["transport-infos", formData.folderId],
            });
        },
    });

    return mutation;
}
