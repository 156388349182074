import { Controller } from "react-hook-form";

import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { InputAdornment, TextField } from "@mui/material";

type Props = {
    name: string;
    label: string;
    defaultValue?: string;
};

function FormSearch({ name, label, defaultValue = "" }: Props) {
    return (
        <Controller
            name={name}
            defaultValue={defaultValue}
            render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
                <TextField
                    inputRef={ref}
                    onChange={onChange}
                    value={value}
                    fullWidth
                    id={name}
                    label={label}
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchRoundedIcon />
                            </InputAdornment>
                        ),
                    }}
                    error={!!error}
                    helperText={error ? error.message : null}
                />
            )}
        />
    );
}

export default FormSearch;
