import { useMutation } from "@tanstack/react-query";

import { queryClient } from "../../../config/react-query";
import { useToast } from "../../../hooks/useToast";
import { stockageCancel } from "./actions";

export function useStockCancel() {
    const { successToast } = useToast();

    const mutation = useMutation({
        mutationFn: stockageCancel,
        onSuccess: () => successToast("Le stockage a bien été annulé"),
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ["folders"],
            });
        },
    });

    return mutation;
}
