import apiClient from "../../../config/api-client";
import { ENDPOINT } from "./config";
import { alphabetical, unique } from "../../../utils/collection";

import type { RoadmapDetails } from "../types/roadmap-details";
import type { Roadmap } from "../types/roadmap";

export async function getRoadmaps(): Promise<Roadmap[]> {
    const { data } = await apiClient.post<Roadmap[]>(ENDPOINT, {
        classe: "tf_dossier",
        methode: "get_all_feuilles_de_route",
        params: [],
    });

    return alphabetical(
        unique(data, (roadmap) => roadmap.id),
        (roadmap) => roadmap.date,
    );
}

export async function getRoadmap(id: string): Promise<RoadmapDetails> {
    const response = await apiClient.post(ENDPOINT, {
        classe: "tf_dossier",
        methode: "get_details_feuille_de_route",
        params: [id],
    });

    return response.data;
}

export async function confirmRoadmap(folderId: number): Promise<void> {
    const response = await apiClient.post(ENDPOINT, {
        classe: "tf_dossier",
        methode: "form_confirmation_organisation",
        params: [folderId],
    });

    return response.data;
}
