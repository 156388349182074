import { Controller } from "react-hook-form";

import { TextField } from "@mui/material";

type Props = {
    name: string;
    label: string;
    defaultValue?: string;
    multiline?: boolean;
    onFocus?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
};
function FormText({
    name,
    label,
    defaultValue = "",
    multiline = false,
    onFocus = undefined,
}: Props) {
    return (
        <Controller
            name={name}
            defaultValue={defaultValue}
            render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
                <TextField
                    onFocus={onFocus}
                    inputRef={ref}
                    onChange={onChange}
                    value={value}
                    fullWidth
                    id={name}
                    label={label}
                    variant="standard"
                    error={!!error}
                    helperText={error ? error.message : null}
                    multiline={multiline}
                    minRows={3}
                />
            )}
        />
    );
}

export default FormText;
