import AccessTimeFilledRoundedIcon from "@mui/icons-material/AccessTimeFilledRounded";
import TodayRoundedIcon from "@mui/icons-material/TodayRounded";
import {
    Card,
    CardContent,
    Container,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
} from "@mui/material";

import PageHeader from "../../components/PageHeader";
import ReportForm from "../../features/worksite/components/ReportForm";

function WorkSiteReportPage() {
    return (
        <>
            <PageHeader title="Rapport de tir" />
            <Container sx={{ py: 4 }} maxWidth="sm">
                <Stack spacing={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" fontWeight="bold">
                                Borgo
                            </Typography>
                            <List dense>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <TodayRoundedIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary="09 juillet 2023" />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <AccessTimeFilledRoundedIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary="Durée approximative : 23min 25s" />
                                </ListItem>
                            </List>
                        </CardContent>
                    </Card>
                    <ReportForm />
                </Stack>
            </Container>
        </>
    );
}

export default WorkSiteReportPage;
