import { useQuery } from "@tanstack/react-query";

import { getRoadmaps } from "./actions";
import { QUERY_KEY } from "./config";

export function useGetRoadmaps() {
    const query = useQuery({
        queryKey: [QUERY_KEY],
        queryFn: getRoadmaps,
    });

    return query;
}
