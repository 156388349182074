import { FormProvider, useForm, useWatch } from "react-hook-form";

import { Stack, Typography } from "@mui/material";

import FormSearch from "../../../../components/form/FormSearch";
import { isEmpty } from "../../../../utils/object";
import { getFilteredProducts, getProductsByDepot } from "../../functions";
import { Depot } from "../../types/depot";
import { Product } from "../../types/product";
import RamassageAccordion from "./RamassageAccordion";

type Props = {
    products: Product[];
    depots: Depot[];
};

function RamassageProductsList({ products = [], depots }: Props) {
    const form = useForm();
    const search = useWatch({ control: form.control, name: "search", defaultValue: "" });

    const filteredProducts = getFilteredProducts(products, search);
    const productsByDepot = getProductsByDepot(filteredProducts);

    return (
        <Stack spacing={5}>
            <FormProvider {...form}>
                <form>
                    <FormSearch name="search" label="Rechercher" />
                </form>
            </FormProvider>

            <Stack spacing={2}>
                {isEmpty(productsByDepot) ? (
                    <Typography>Aucun produit n'a été trouvé</Typography>
                ) : (
                    Object.entries(productsByDepot).map(([depotId, items]) => (
                        <RamassageAccordion
                            key={depotId}
                            depot={
                                depots.find(
                                    (depot) => depot.depot === parseInt(depotId, 10),
                                ) as Depot
                            }
                            products={items}
                        />
                    ))
                )}
            </Stack>
        </Stack>
    );
}

export default RamassageProductsList;
