import React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    Accordion as MuiAccordion,
    AccordionDetails,
    AccordionProps,
    AccordionSummary,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";

type Props = AccordionProps & {
    title: string;
    children: React.ReactNode;
    icon?: React.ReactNode;
};

function Accordion({ title, children, icon = null, ...props }: Props) {
    const { palette } = useTheme();

    return (
        <MuiAccordion {...props}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Stack direction="row" spacing={2} alignItems="center">
                    {icon}
                    <Typography>{title}</Typography>
                </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ background: palette.background.default, px: 0 }}>
                {children}
            </AccordionDetails>
        </MuiAccordion>
    );
}

export default Accordion;
