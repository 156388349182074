import { Dayjs } from "dayjs";
import { Controller } from "react-hook-form";

import { DatePicker } from "@mui/x-date-pickers";

import datetime from "../../config/datetime";

type Props = {
    name: string;
    label: string;
    defaultValue?: Dayjs;
};

function FormDatePicker({ name, label, defaultValue = datetime() }: Props) {
    return (
        <Controller
            name={name}
            defaultValue={defaultValue}
            render={({ field: { onChange, value, ref } }) => (
                <DatePicker
                    inputRef={ref}
                    value={value}
                    onChange={onChange}
                    slotProps={{
                        toolbar: {
                            hidden: true,
                            toolbarFormat: "MMMM YYYY",
                        },
                        actionBar: { actions: [] },
                        textField: { variant: "standard" },
                    }}
                    format="MMMM YYYY"
                    label={label}
                    views={["year", "month"]}
                    openTo="month"
                    disableFuture
                    closeOnSelect
                    maxDate={datetime().subtract(0, "month")}
                    minDate={datetime().year(2015)}
                />
            )}
        />
    );
}

export default FormDatePicker;
