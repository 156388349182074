import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { Button, Stack } from "@mui/material";

import Accordion from "../../../../components/Accordion";
import { Depot } from "../../types/depot";
import { Product } from "../../types/product";
import ProductItemRamassage from "./ProductItemRamassage";

type Props = {
    products: Product[];
    depot: Depot;
};

function RamassageAccordion({ products, depot }: Props) {
    const quantityLeft = depot.quantite_restante;

    const icon = (
        <Button
            color={quantityLeft === 0 ? "success" : "primary"}
            sx={{
                pointerEvents: "none",
                minWidth: 0,
                borderRadius: 50,
                width: 40,
                height: 40,
            }}
            variant="outlined"
        >
            {quantityLeft === 0 ? <CheckRoundedIcon /> : quantityLeft}
        </Button>
    );

    return (
        <Accordion title={`Dépôt ${depot.depot}`} icon={icon}>
            <Stack spacing={3} sx={{ pt: 3 }}>
                {products.map((product) => (
                    <ProductItemRamassage product={product} key={product.id} />
                ))}
            </Stack>
        </Accordion>
    );
}

export default RamassageAccordion;
