import jwt_decode from "jwt-decode";
import { createContext, PropsWithChildren, useEffect, useMemo, useReducer } from "react";

import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { useLocalStorage } from "../../../../hooks";
import { Jwt } from "../../types/jwt";
import { User } from "../../types/user";
import { ContractType } from "../../types/contractType";

interface IAuthContext {
    isLoading: boolean;
    user: User | null;
    login: (token: string) => void;
    logout: () => void;
}

export const AuthContext = createContext<IAuthContext>({
    isLoading: true,
    user: null,
    login: () => {},
    logout: () => {},
});

type AuthState = {
    isLoading: boolean;
    user: User | null;
};

const enum AuthAction {
    RESTORE,
    LOGIN,
    LOGOUT,
}

type ReducerAction = {
    type: AuthAction;
    user?: User;
};

const reducer = (state: AuthState, action: ReducerAction): AuthState => {
    switch (action.type) {
        case AuthAction.RESTORE:
            return {
                ...state,
                user: action.user || null,
                isLoading: false,
            };
        case AuthAction.LOGIN:
            return { ...state, user: action.user || null };
        case AuthAction.LOGOUT:
            return { ...state, user: null };
        default:
            throw new Error();
    }
};

export function AuthProvider({ children }: PropsWithChildren) {
    const navigate = useNavigate();
    const { getItem, setItem, removeItem } = useLocalStorage();

    const [state, dispatch] = useReducer(reducer, {
        isLoading: true,
        user: null,
    });

    const LOCAL_STORAGE_KEY = "token";

    const getUserFromToken = (token: string): User => {
        const decoded: Jwt = jwt_decode(token);
        const { user } = decoded.data.data;
        return user;
    };

    useEffect(() => {
        // Fetch the token from storage then navigate to our appropriate place
        const bootstrapAsync = async () => {
            const token = getItem(LOCAL_STORAGE_KEY);
            if (!token) {
                dispatch({ type: AuthAction.RESTORE, user: undefined });
                return;
            }

            // TODO:
            // After restoring token, we may need to validate it in production apps

            // This will switch to the App screen or Auth screen and this loading
            // screen will be unmounted and thrown away.
            const user = getUserFromToken(token);
            dispatch({ type: AuthAction.RESTORE, user });
            if (user.admin_contrat_type !== ContractType.CDI) {
                navigate("/dashboard");
            }
        };

        bootstrapAsync();
    }, []);

    const authContext = useMemo(
        () => ({
            isLoading: state.isLoading,
            user: state.user,
            login: (token: string) => {
                setItem(LOCAL_STORAGE_KEY, token);
                dispatch({ type: AuthAction.LOGIN, user: getUserFromToken(token) });
            },

            logout: () => {
                removeItem(LOCAL_STORAGE_KEY);
                dispatch({ type: AuthAction.LOGOUT });
            },
        }),
        [state],
    );

    if (state.isLoading) {
        return <LoadingSpinner />;
    }

    return <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>;
}
