import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "react-toastify/dist/ReactToastify.css";

import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { queryClient } from "./config/react-query";
import { logError } from "./config/sentry";
import theme from "./config/theme";
import { toastifyConfig } from "./config/toast";
import { LocalizationProvider } from "./contexts/localizationContext";
import { AuthProvider } from "./features/auth/contexts/authContext";
import AppRoutes from "./routes/AppRoutes";
import { ErrorBoundary } from "./components/ErrorBoundary";

function App() {
    return (
        <ThemeProvider theme={theme}>
            <ErrorBoundary onError={logError}>
                <QueryClientProvider client={queryClient}>
                    <CssBaseline />
                    <BrowserRouter>
                        <AuthProvider>
                            <LocalizationProvider>
                                <AppRoutes />
                                <ToastContainer {...toastifyConfig} />
                            </LocalizationProvider>
                        </AuthProvider>
                    </BrowserRouter>
                    <ReactQueryDevtools />
                </QueryClientProvider>
            </ErrorBoundary>
        </ThemeProvider>
    );
}

export default App;
