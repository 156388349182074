import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { queryClient } from "../../config/react-query";
import { useAuthContext } from "../../features/auth/contexts/authContext";

function LogoutPage() {
    const { logout } = useAuthContext();
    const navigate = useNavigate();

    useEffect(() => {
        logout();
        queryClient.removeQueries();
        navigate("/");
    }, [logout, navigate]);

    return null;
}

export default LogoutPage;
