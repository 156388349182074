import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Stack } from "@mui/material";

import { AxiosError } from "axios";
import FormText from "../../components/form/FormText";
import { useToast } from "../../hooks/useToast";
import { useForgotPassword } from "./api/useForgotPassword";
import { ForgotPasswordFormData } from "./types/forgot-password-form";
import ForgotPasswordSchema from "./validation/ForgotPasswordSchema";

function ForgotPasswordForm() {
    const navigate = useNavigate();
    const { successToast, errorToast } = useToast();

    const form = useForm<ForgotPasswordFormData>({ resolver: yupResolver(ForgotPasswordSchema) });

    const handleSuccess = () => {
        successToast("Un email pour réinitialiser votre mot de passe vous a été envoyé");
        navigate("/login");
    };

    const handleError = (error: AxiosError) => {
        const { message } = error;
        const formErrors = (error.response?.data as any)?.error?.errors;

        if (formErrors) {
            Object.entries(formErrors).forEach(([name, errorMessage]) => {
                form.setError(name as keyof ForgotPasswordFormData, {
                    message: errorMessage as string,
                });
            });
        }

        errorToast(message);
    };

    const forgotPasswordMutation = useForgotPassword();
    const onSubmit = (formData: ForgotPasswordFormData) =>
        forgotPasswordMutation.mutate(formData, {
            onSuccess: handleSuccess,
            onError: handleError,
        });

    return (
        <FormProvider {...form}>
            <Stack spacing={5}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <Stack spacing={5} justifyContent="center" alignItems="center">
                        <FormText name="email" label="Email" />

                        <Button type="submit" fullWidth variant="contained">
                            Réinitialiser mot de passe
                        </Button>
                    </Stack>
                </form>
            </Stack>
        </FormProvider>
    );
}

export default ForgotPasswordForm;
