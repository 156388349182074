import { useQuery } from "@tanstack/react-query";

import { getStockageInfos } from "./actions";

export function useGetStockageInfos(folderId: string) {
    const query = useQuery({
        queryKey: ["folders", "stockage", folderId],
        queryFn: () => getStockageInfos(folderId),
        staleTime: 0,
    });

    return query;
}
