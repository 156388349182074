import { WorkSite } from "./types/work-site";

interface DataObject {
    id: string;
    nom: string;
    lieu_tir: string;
    date: string;
    plan_tir: string;
    implantation_tir: string;
    document_transport: string;
    rapport?: boolean;
}

export function mergeWorkSites(data: DataObject[]): WorkSite[] {
    return data.reduce((result: WorkSite[], current) => {
        const existingItem = result.find((item) => item.id === current.id);
        if (existingItem) {
            existingItem.dates.push(current.date);
        } else {
            const newItem: WorkSite = {
                id: current.id,
                nom: current.nom,
                lieu_tir: current.lieu_tir,
                dates: [current.date],
                plan_tir: current.plan_tir,
                implantation_tir: current.implantation_tir,
                document_transport: current.document_transport,
            };

            if (current.rapport !== undefined) {
                newItem.rapport = current.rapport;
            }

            result.push(newItem);
        }
        return result;
    }, []);
}
