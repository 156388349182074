import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useAuthContext } from "./contexts/authContext";
import { Role } from "./types/role";
import { ContractType } from "./types/contractType";

type Props = {
    allowedContrats?: ContractType[];
    allowedRoles?: Role[];
};

function ProtectedRoute({ allowedContrats, allowedRoles }: Props) {
    const { user } = useAuthContext() || {};
    const location = useLocation();

    if (!user) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (allowedRoles && !allowedRoles.includes(user.fonction)) {
        return <Navigate to="/unauthorized" state={{ from: location }} replace />;
    }

    if (allowedContrats && !allowedContrats.includes(user.admin_contrat_type)) {
        return <Navigate to="/unauthorized" state={{ from: location }} replace />;
    }

    return <Outlet />;
}

export default ProtectedRoute;

ProtectedRoute.defaultProps = {
    allowedRoles: null,
};
