import { captureException, extraErrorDataIntegration, init } from "@sentry/react";

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN as string;

export const initSentry = () => {
    init({
        dsn: SENTRY_DSN,
        integrations: [extraErrorDataIntegration()],
    });
};

export const logError = (error: Error, errorInfo: React.ErrorInfo) => {
    captureException(error, {
        extra: { componentStack: errorInfo.componentStack || "" },
    });
};
