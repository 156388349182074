import { AxiosRequestConfig, AxiosResponse } from "axios";

import apiClient from "../../../config/api-client";
import { CostFormData } from "../types/cost-form";
import { Payment } from "../types/payment";
import { ENDPOINT } from "./config";

import type { Contract } from "../types/contract";
import type { Cost } from "../types/cost";

export async function getContrats(): Promise<Contract[]> {
    const { data } = await apiClient.post(ENDPOINT, {
        classe: "tf_contrat",
        methode: "get_contrat",
        params: [],
    });

    return data;
}

export async function getContratsHistory(date: string): Promise<Contract[]> {
    const { data } = await apiClient.post(ENDPOINT, {
        classe: "tf_contrat",
        methode: "get_contrat_historique",
        params: [date],
    });

    return data;
}

export async function getCosts(contractId: string): Promise<Cost[]> {
    const { data } = await apiClient.post(ENDPOINT, {
        classe: "tf_contrat",
        methode: "get_all_frais_contrat",
        params: [contractId],
    });

    return data;
}

export async function createCost(
    request: CostFormData,
    config?: AxiosRequestConfig,
): Promise<AxiosResponse> {
    const response = await apiClient.post(
        ENDPOINT,
        {
            classe: "tf_contrat",
            methode: "form_lier_contrat_frais",
            params: [request],
            file: request.ticket,
        },
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            ...config,
        },
    );

    return response;
}

export async function deleteCost(lier_contrat_frais_id: string): Promise<AxiosResponse> {
    const response = await apiClient.post(ENDPOINT, {
        classe: "tf_contrat",
        methode: "delete_frais",
        params: [lier_contrat_frais_id],
    });

    return response;
}

export async function getPayments(): Promise<Payment[]> {
    const { data } = await apiClient.post(ENDPOINT, {
        classe: "tf_contrat",
        methode: "get_all_reglements",
        params: [],
    });

    return data;
}
