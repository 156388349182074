import { AxiosError } from "axios";

import { useMutation } from "@tanstack/react-query";

import { LoginFormData } from "../types/login-form";
import { login } from "./actions";

export function useLogin() {
    const mutation = useMutation<string, AxiosError, LoginFormData>({
        mutationFn: login,
    });

    return mutation;
}
