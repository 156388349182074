import { Container, IconButton, Paper, Stack, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate } from "react-router-dom";

type Props = {
    title: string;
    showBackButton?: boolean;
};

function PageHeader({ title, showBackButton = true }: Props) {
    const navigate = useNavigate();

    const handleOnClick = () => {
        navigate(-1);
    };

    return (
        <Paper square elevation={1}>
            <Container maxWidth="sm">
                <Stack
                    sx={{ py: 2 }}
                    alignItems="center"
                    justifyContent="space-between"
                    direction="row"
                >
                    {showBackButton ? (
                        <IconButton onClick={handleOnClick}>
                            <KeyboardArrowLeftIcon />
                        </IconButton>
                    ) : (
                        <div />
                    )}
                    <Typography component="p" textAlign="center" variant="button">
                        {title}
                    </Typography>
                    <div />
                </Stack>
            </Container>
        </Paper>
    );
}

export default PageHeader;
