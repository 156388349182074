import { AxiosResponse } from "axios";

import apiClient from "../../../config/api-client";
import { EditProfileFormData } from "../types/edit-profile-form";
import { ENDPOINT } from "./config";

import type { User } from "../types/user";

export async function getUser(): Promise<User> {
    const response = await apiClient.post(ENDPOINT, {
        classe: "tf_profil",
        methode: "get_info",
        params: [],
    });

    return response.data;
}

export async function editProfile(request: EditProfileFormData): Promise<AxiosResponse> {
    const response = await apiClient.post(ENDPOINT, {
        classe: "tf_profil",
        methode: "signaler_changement",
        params: request,
    });

    return response;
}
