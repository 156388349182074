import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import FormText from "../../../../components/form/FormText";
import { hideKeyboard } from "../../../../utils/form";

type Props = {
    onSubmit: (code: string) => void;
};

type FormData = {
    scan: string;
};

function DouchetteForm({ onSubmit }: Props) {
    const form = useForm<FormData>();

    useEffect(() => {
        form.setFocus("scan");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnSubmit = (formData: FormData) => {
        onSubmit(formData.scan);
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleOnSubmit)} noValidate>
                <FormText
                    label="Commencez à scanner"
                    name="scan"
                    onFocus={(e) => hideKeyboard(e.target)}
                />
            </form>
        </FormProvider>
    );
}

export default DouchetteForm;
