import { Container, Stack, Typography } from "@mui/material";

import LoginForm from "../../features/auth/LoginForm";
import { useWindowSize } from "../../hooks";

function LoginPage() {
    const { height } = useWindowSize();

    return (
        <Container maxWidth="sm">
            <Stack sx={{ height }} gap={4} justifyContent="center">
                <LoginForm />
                <Typography align="center">version 2.1.0</Typography>
            </Stack>
        </Container>
    );
}

export default LoginPage;
