import { FormProvider, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";

import FormText from "../../components/form/FormText";
import { useEditProfile } from "./api/useEditProfile";
import { EditProfileFormData } from "./types/edit-profile-form";
import editProfileSchema from "./validation/editProfileSchema";

type Props = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

function EditProfileForm({ setOpen }: Props) {
    const form = useForm<EditProfileFormData>({ resolver: yupResolver(editProfileSchema) });

    const editProfileMutation = useEditProfile();
    const onSubmit = async (formData: EditProfileFormData) => {
        await editProfileMutation.mutateAsync(formData);
        setOpen(false);
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
                <Stack spacing={3}>
                    <FormText
                        name="change"
                        label="Quel(s) changement(s) sont à effectuer ?"
                        multiline
                    />
                    <LoadingButton
                        loading={editProfileMutation.isLoading}
                        type="submit"
                        fullWidth
                        variant="contained"
                    >
                        Envoyer
                    </LoadingButton>
                </Stack>
            </form>
        </FormProvider>
    );
}

export default EditProfileForm;
