import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import { queryClient } from "../../../config/react-query";
import { useToast } from "../../../hooks/useToast";
import { WorkSiteReportFormData } from "../types/work-site-report-form";
import { createWorkSiteReport } from "./actions";
import { QUERY_KEY } from "./config";

export function useCreateWorkSiteReport(workSiteId: string) {
    const { successToast, errorToast } = useToast();
    const navigate = useNavigate();

    const mutation = useMutation<string, AxiosError, WorkSiteReportFormData>({
        mutationFn: (request) => createWorkSiteReport(workSiteId, request),
        onSuccess: () => {
            successToast("Le rapport a été envoyé");
            navigate("/dashboard/worksites");
        },
        onError: () => errorToast("Une erreur est survenue"),
        onSettled: () => queryClient.invalidateQueries([QUERY_KEY]),
    });

    return mutation;
}
