/* eslint-disable @typescript-eslint/naming-convention */
import { Link, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { RoadmapDetails } from "../types/roadmap-details";

type Props = {
    roadmap: RoadmapDetails;
};

function RoadmapInfos({ roadmap }: Props) {
    const {
        chantier,
        lieux_de_tir,
        nom_contact,
        date_de_tir,
        telephone,
        nombre_jours_chantier,
        informations_generales,
    } = roadmap.jours_chantier[0];

    return (
        <Table size="small">
            <TableBody>
                <TableRow>
                    <TableCell variant="head">Chantier</TableCell>
                    <TableCell align="right">{chantier}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell variant="head">Date de tir</TableCell>
                    <TableCell align="right">{date_de_tir}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell variant="head">Lieu de tir</TableCell>
                    <TableCell align="right">{lieux_de_tir}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell variant="head">Nom contact</TableCell>
                    <TableCell align="right">{nom_contact}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell variant="head">Téléphone contact</TableCell>
                    <TableCell align="right">
                        <Link href={`tel:${telephone}`}>{telephone}</Link>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell variant="head">Nbr. jour(s) chantier</TableCell>
                    <TableCell align="right">{nombre_jours_chantier}</TableCell>
                </TableRow>
                <TableRow
                    sx={{
                        verticalAlign: "top",
                        "&:last-child td": { border: 0 },
                    }}
                >
                    <TableCell variant="head">Informations générale</TableCell>
                    <TableCell>{informations_generales}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
}

export default RoadmapInfos;
