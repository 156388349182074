import { useMutation, useQueryClient } from "@tanstack/react-query";

import { deleteCost } from "./actions";
import { QUERY_KEY } from "./config";
import { useToast } from "../../../hooks";

export function useDeleteCost(contractId: string) {
    const { errorToast, successToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: deleteCost,
        onSuccess: () => successToast("Le frais a été supprimé"),
        onError: () => errorToast("Une erreur s'est produite lors de la suppression"),
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: [QUERY_KEY, contractId, "costs"],
            });
        },
    });

    return mutation;
}
