import jwtDecode, { JwtPayload } from "jwt-decode";

export function getAdminId() {
    const token = localStorage.getItem("token");

    if (!token) return "";

    const decoded = jwtDecode<JwtPayload>(token);

    // Erreur parce que format jwt chelou ...
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return decoded.data.data.user.user_id;
}
