import { useMutation } from "@tanstack/react-query";

import { useToast } from "../../../hooks/useToast";
import { editProfile } from "./actions";

export function useEditProfile() {
    const { successToast } = useToast();

    const mutation = useMutation({
        mutationFn: editProfile,
        onSuccess: () => successToast("Votre demande de changement a bien été envoyée"),
    });

    return mutation;
}
