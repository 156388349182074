import { Controller } from "react-hook-form";

import { InputProps as InputPropsInterface, TextField } from "@mui/material";

type Props = {
    name: string;
    label: string;
    defaultValue?: number | null;
    InputProps?: InputPropsInterface;
    selectOnFocus?: boolean;
};
function FormNumber({ name, label, defaultValue = 0, InputProps, selectOnFocus = false }: Props) {
    return (
        <Controller
            name={name}
            defaultValue={defaultValue}
            render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
                <TextField
                    inputRef={ref}
                    type="number"
                    onChange={onChange}
                    value={value}
                    fullWidth
                    id={name}
                    label={label}
                    variant="standard"
                    error={!!error}
                    helperText={error ? error.message : null}
                    InputProps={InputProps}
                    onFocus={(event) => (value === 0 || selectOnFocus) && event.target.select()}
                />
            )}
        />
    );
}

export default FormNumber;
