import { useState } from "react";
import { useParams } from "react-router-dom";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Button, Container, Divider, Stack } from "@mui/material";

import PageHeader from "../../components/PageHeader";
import { useGetContracts } from "../../features/contracts/api/useGetContracts";
import CostForm from "../../features/contracts/components/CostForm";
import CostsList from "../../features/contracts/components/CostsList";

function ContractCostsPage() {
    const { contractId } = useParams();
    const [showForm, setShowForm] = useState(false);

    const contractsQuery = useGetContracts();
    const contract = contractsQuery?.data?.find((c) => c.contrat_id === contractId);

    const onCreateCostSuccess = () => {
        setTimeout(() => {
            setShowForm(false);
        }, 1000);
    };

    if (!contract) return null;

    return (
        <>
            <PageHeader title="Mes frais" />
            <Container sx={{ py: 4 }} maxWidth="sm">
                <Stack spacing={3}>
                    {showForm ? (
                        <CostForm onSuccess={onCreateCostSuccess} contract={contract} />
                    ) : (
                        <Button
                            onClick={() => setShowForm(true)}
                            fullWidth
                            variant="contained"
                            endIcon={<AddRoundedIcon />}
                        >
                            Ajouter un frais
                        </Button>
                    )}

                    <Divider />

                    <CostsList />
                </Stack>
            </Container>
        </>
    );
}

export default ContractCostsPage;
