import { toast, ToastOptions } from "react-toastify";

import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { Box, Typography } from "@mui/material";

export const useToast = () => {
    function CloseButton() {
        return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <CloseRoundedIcon />
            </Box>
        );
    }

    const successToast = (message: string, options?: ToastOptions) => {
        toast.success(() => <Typography variant="subtitle2">{message}</Typography>, {
            icon: <CheckCircleRoundedIcon />,
            closeButton: <CloseButton />,
            ...options,
        });
    };

    const errorToast = (message: string, options?: ToastOptions) => {
        toast.error(() => <Typography variant="subtitle2">{message}</Typography>, {
            icon: <ErrorRoundedIcon />,
            closeButton: <CloseButton />,
            ...options,
        });
    };

    return { successToast, errorToast };
};
