import { ChangeEvent } from "react";
import { Controller } from "react-hook-form";

import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup,
} from "@mui/material";

type Props = {
    name: string;
    label: string;
    options: {
        value: string;
        label: string;
    }[];
    defaultValue?: string | null;
    row?: boolean;
    boolean?: boolean;
};

function FormRadio({
    name,
    label,
    options,
    defaultValue = null,
    row = false,
    boolean = false,
}: Props) {
    const transform = {
        input: (value: string | boolean) => {
            if (!boolean) return value;
            if (value === false) return "0";
            if (value === true) return "1";
            return defaultValue;
        },
        output: (e: ChangeEvent<HTMLInputElement>) => {
            if (!boolean) return e;
            if (e.target.value === "0") return false;
            if (e.target.value === "1") return true;
            return null;
        },
    };

    return (
        <Controller
            name={name}
            defaultValue={defaultValue}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl error={!!error}>
                    <FormLabel id={name}>{label}</FormLabel>
                    <RadioGroup
                        row={row}
                        aria-labelledby={name}
                        value={transform.input(value)}
                        onChange={(e) => onChange(transform.output(e))}
                    >
                        {options.map((option) => (
                            <FormControlLabel
                                key={option.value}
                                value={option.value}
                                control={<Radio />}
                                label={option.label}
                            />
                        ))}
                    </RadioGroup>
                    {error ? <FormHelperText sx={{ mx: 0 }}>{error.message}</FormHelperText> : null}
                </FormControl>
            )}
        />
    );
}

export default FormRadio;
