import { useParams } from "react-router-dom";

import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack, Typography } from "@mui/material";

import BoxIcon from "../../../../components/icons/BoxIcon";
import { useCartonLine } from "../../api/useCartonLine";
import { useGrappageLine } from "../../api/useGrappageLine";
import { GrappageLine } from "../../types/grappage";

type Props = {
    line: GrappageLine;
};

function GrappageLineItem({ line }: Props) {
    const { folderId } = useParams();
    const isPickedUp = line.carton === "1";
    const stateColor = isPickedUp ? "success" : "warning";

    const grappageLineMutation = useGrappageLine(folderId as string);
    const handleOnClickGrappe = () => {
        const grappeAction = line.grappe === "0" ? 1 : 0;
        grappageLineMutation.mutate({ lineId: line.id, grapper: grappeAction });
    };

    const cartonLineMutation = useCartonLine(folderId as string);
    const handleOnClickCarton = () => {
        const cartonAction = line.carton === "0" ? 1 : 0;
        cartonLineMutation.mutate({ lineId: line.id, carton: cartonAction });
    };

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={5}>
            <Stack direction="row" spacing={2}>
                <Box sx={{ width: 2, minHeight: "100%", bgcolor: `${stateColor}.main` }} />
                <Stack>
                    <Typography variant="caption">{line.nom}</Typography>
                    <Typography variant="caption">Ref : {line.reference}</Typography>
                    <Typography variant="caption" sx={{ color: `${stateColor}.main` }}>
                        Quantité : {line.quantite}
                    </Typography>
                    <Typography variant="caption" sx={{ color: `${stateColor}.main` }}>
                        Retard : {line.retard}
                    </Typography>
                </Stack>
            </Stack>

            <Stack direction="column" spacing={1}>
                {line.carton === "0" ? (
                    <LoadingButton
                        loading={grappageLineMutation.isLoading}
                        sx={{
                            minWidth: 0,
                            borderRadius: 50,
                            width: 40,
                            height: 40,
                        }}
                        variant="outlined"
                        color={line.grappe === "0" ? "primary" : "error"}
                        onClick={handleOnClickGrappe}
                    >
                        {line.grappe === "0" ? (
                            <CheckRoundedIcon color="primary" />
                        ) : (
                            <CloseRoundedIcon color="error" />
                        )}
                    </LoadingButton>
                ) : null}
                {line.grappe === "1" ? (
                    <Button
                        sx={{
                            minWidth: 0,
                            borderRadius: 50,
                            width: 40,
                            height: 40,
                        }}
                        variant="outlined"
                        color={line.carton === "0" ? "primary" : "error"}
                        onClick={handleOnClickCarton}
                    >
                        {line.carton === "0" ? (
                            <BoxIcon color="primary" />
                        ) : (
                            <BoxIcon color="error" />
                        )}
                    </Button>
                ) : null}
            </Stack>
        </Stack>
    );
}

export default GrappageLineItem;
