import { Stack, Typography } from "@mui/material";

import { Contract } from "../types/contract";
import ContractHistoryCard from "./ContractHistoryCard";

type Props = {
    contracts: Contract[];
};

function ContractHistoryList({ contracts }: Props) {
    return (
        <Stack spacing={2}>
            <Typography>Historique des contrats</Typography>
            {contracts.map((contract) => (
                <ContractHistoryCard key={contract.contrat_id} contract={contract} />
            ))}
        </Stack>
    );
}

export default ContractHistoryList;
