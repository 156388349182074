/* eslint-disable @typescript-eslint/naming-convention */
import { Table, TableBody, TableCell, TableRow } from "@mui/material";

import { RoadmapDetails } from "../types/roadmap-details";

type Props = {
    roadmap: RoadmapDetails;
};

function RoadmapTransport({ roadmap }: Props) {
    const {
        numero_camion,
        nom_du_camion,
        chauffeur_nom,
        camion_volume,
        lieux_date_recup,
        lieux_date_retour,
        organisation_transport,
    } = roadmap.transport_info;

    return (
        <Table size="small">
            <TableBody>
                <TableRow>
                    <TableCell variant="head">Immatriculation</TableCell>
                    <TableCell align="right">{numero_camion}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell variant="head">Loueur</TableCell>
                    <TableCell align="right">{nom_du_camion}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell variant="head">Conducteur</TableCell>
                    <TableCell align="right">{chauffeur_nom}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell variant="head">Type</TableCell>
                    <TableCell align="right">{camion_volume}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell variant="head">Lieu et date de récupération</TableCell>
                    <TableCell align="right">
                        <p dangerouslySetInnerHTML={{ __html: lieux_date_recup }} />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell variant="head">Lieu et date de retour</TableCell>
                    <TableCell align="right">
                        <p dangerouslySetInnerHTML={{ __html: lieux_date_retour }} />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell variant="head">Organisation transport</TableCell>
                    <TableCell align="right">{organisation_transport}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
}

export default RoadmapTransport;
