import "filepond/dist/filepond.min.css";

import { MuiFileInput } from "mui-file-input";
import { Controller } from "react-hook-form";

import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import { FormControl, FormHelperText, InputAdornment } from "@mui/material";

type Props = {
    name: string;
    placeholder: string;
};
function FormFile({ name, placeholder }: Props) {
    return (
        <Controller
            name={name}
            defaultValue={null}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl error={!!error}>
                    <MuiFileInput
                        placeholder={placeholder}
                        value={value}
                        variant="filled"
                        onChange={onChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="end" sx={{ marginRight: 2 }}>
                                    <CameraAltRoundedIcon />
                                </InputAdornment>
                            ),
                        }}
                        error={!!error}
                    />
                    {error ? (
                        <FormHelperText sx={{ marginLeft: 0 }}>{error.message}</FormHelperText>
                    ) : null}
                </FormControl>
            )}
        />
    );
}

export default FormFile;
