import axios, { AxiosError } from "axios";

const { VITE_API_BASE_URL } = import.meta.env;

const apiClient = axios.create({
    baseURL: `${VITE_API_BASE_URL}`,
    headers: {
        Accept: "application/json",
        "Content-type": "application/json",
    },
});

apiClient.interceptors.request.use(
    (config) => {
        const newConfig = { ...config };
        const token = localStorage.getItem("token");
        if (token) {
            newConfig.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
        }
        return newConfig;
    },
    (error) => console.log("ERROR", error),
);

apiClient.interceptors.response.use(
    (response) => {
        if (response.data && response.data.success === false) {
            const { code, message } = response.data.error;

            const axiosError = new AxiosError(message, code, response.config, response.request, {
                status: code,
                data: response.data,
                statusText: "Server error",
                config: response.config,
                headers: response.headers,
            });
            return Promise.reject(axiosError);
        }

        return response;
    },
    (error: AxiosError) => {
        if (error.response?.status === 401 && error.config?.url !== "jwt-authentication.php") {
            localStorage.removeItem("token");
            window.location.reload();
        }
        return Promise.reject(error);
    },
);

export default apiClient;

export const MAIN_ENDPOINT = "generer_classe.php";
