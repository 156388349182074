import { Container, Stack } from "@mui/material";

import LoadingSpinner from "../../components/LoadingSpinner";
import PageHeader from "../../components/PageHeader";
import { useGetUser } from "../../features/profile/api/useGetUser";
import ListLinks from "./components/ListLinks";
import ProfileLink from "./components/ProfileLink";

function DashboardPage() {
    const { data: user, isLoading } = useGetUser();

    return (
        <>
            <PageHeader showBackButton={false} title="Tableau de bord" />
            <Container sx={{ py: 4 }} maxWidth="sm">
                {isLoading || !user ? (
                    <LoadingSpinner />
                ) : (
                    <Stack spacing={3}>
                        <ProfileLink user={user} />
                        <ListLinks />
                    </Stack>
                )}
            </Container>
        </>
    );
}

export default DashboardPage;
