import { useParams } from "react-router-dom";

import { Box } from "@mui/material";

import { useStockFolder } from "../../api/useStockFolder";
import { Depot } from "../../types/stockage";
import DepotButton from "./DepotButton";

type Props = {
    depots: Depot[];
    folderWeight: number;
};

const POIDS_MAX = 1450;

function DepotsList({ depots, folderWeight }: Props) {
    const { folderId } = useParams();

    const stockMutation = useStockFolder();

    const stockFolder = (depotId: number) => {
        stockMutation.mutate({ folderId: folderId as string, depotId });
    };

    return (
        <Box
            sx={{
                display: "grid",
                gap: 2,
                gridTemplateColumns: "repeat(2, 1fr)",
            }}
        >
            {depots.map(({ id, nom, poids }) => (
                <DepotButton
                    key={id}
                    name={nom}
                    weight={poids + folderWeight}
                    onClick={() => stockFolder(id)}
                    isError={poids + folderWeight > POIDS_MAX}
                />
            ))}
        </Box>
    );
}

export default DepotsList;
