import { FormProvider, useForm, useWatch } from "react-hook-form";

import { Container, Stack, Typography } from "@mui/material";

import FormDatePicker from "../../components/form/FormDatePicker";
import PageHeader from "../../components/PageHeader";
import datetime from "../../config/datetime";
import { useGetContractsHistory } from "../../features/contracts/api/useGetContractsHistory";
import ContractHistoryList from "../../features/contracts/components/ContractHistoryList";

function HistoryPage() {
    const form = useForm();

    const selectedDate: ReturnType<typeof datetime> = useWatch({
        name: "date",
        control: form.control,
        defaultValue: datetime(),
    });

    const contractsQuery = useGetContractsHistory(selectedDate.toISOString());

    return (
        <>
            <PageHeader title="Historique et règlements" />
            <Container sx={{ py: 4 }} maxWidth="sm">
                <Stack spacing={5}>
                    <FormProvider {...form}>
                        <FormDatePicker name="date" label="Choisissez un mois" />
                    </FormProvider>
                    <Stack spacing={5}>
                        {contractsQuery.data?.length ? (
                            <ContractHistoryList contracts={contractsQuery.data} />
                        ) : (
                            <Typography>
                                Aucun contract trouvé en{" "}
                                {datetime(selectedDate).format("MMMM YYYY")}
                            </Typography>
                        )}
                    </Stack>
                </Stack>
            </Container>
        </>
    );
}

export default HistoryPage;
