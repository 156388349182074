import { useNavigate } from "react-router-dom";

import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { Box, IconButton, Paper, Stack, Typography } from "@mui/material";

import { User } from "../../../features/profile/types/user";

type Props = {
    user: User;
};

function ProfileLink({ user }: Props) {
    const navigate = useNavigate();

    return (
        <Paper elevation={1}>
            <Box onClick={() => navigate("/dashboard/profile")} sx={{ cursor: "pointer" }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ p: 2 }}
                >
                    <Stack>
                        <Typography fontWeight="bold">
                            {user.prenom} {user.nom}
                        </Typography>
                        <Typography variant="subtitle2" component="span">
                            Voir le profil
                        </Typography>
                    </Stack>
                    <Stack>
                        <IconButton aria-label="Voir le profil">
                            <ChevronRightRoundedIcon />
                        </IconButton>
                    </Stack>
                </Stack>
            </Box>
        </Paper>
    );
}

export default ProfileLink;
