import { useNavigate } from "react-router-dom";

import AssignmentTurnedInRoundedIcon from "@mui/icons-material/AssignmentTurnedInRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import EngineeringSharpIcon from "@mui/icons-material/EngineeringSharp";
import HistoryRoundedIcon from "@mui/icons-material/HistoryRounded";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";

function ListLinks() {
    const navigate = useNavigate();

    const LINKS = [
        {
            title: "Chantiers en cours",
            icon: <EngineeringSharpIcon />,
            onClick: () => navigate("worksites"),
        },
        {
            title: "Feuilles de route",
            icon: <DescriptionRoundedIcon />,
            onClick: () => navigate("roadmaps"),
        },
        {
            title: "Gestion des contrats",
            icon: <AssignmentTurnedInRoundedIcon />,
            onClick: () => navigate("contracts"),
        },
        {
            title: "Historique et règlements",
            icon: <HistoryRoundedIcon />,
            onClick: () => navigate("contracts/history"),
            showDivider: false,
        },
    ];

    return (
        <List>
            {LINKS.map((link, index) => (
                <ListItem
                    key={link.title}
                    disablePadding
                    divider={index !== LINKS.length - 1}
                    secondaryAction={
                        <IconButton onClick={link.onClick}>
                            <KeyboardArrowRightIcon />
                        </IconButton>
                    }
                >
                    <ListItemButton sx={{ py: 2 }} onClick={link.onClick}>
                        <ListItemIcon>{link.icon}</ListItemIcon>
                        <ListItemText primary={link.title} />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    );
}

export default ListLinks;
