/* eslint-disable @typescript-eslint/naming-convention */
import { useNavigate } from "react-router-dom";

import EuroRoundedIcon from "@mui/icons-material/EuroRounded";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PictureAsPdfRoundedIcon from "@mui/icons-material/PictureAsPdfRounded";
import {
    Card,
    CardActions,
    CardContent,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";

import datetime from "../../../config/datetime";

import type { Contract } from "../types/contract";

type Props = {
    contract: Contract;
    showContractPdf?: boolean;
};

function ContractCard({ contract, showContractPdf = true }: Props) {
    const navigate = useNavigate();

    const { contrat_date_debut, contrat_date_fin } = contract;

    return (
        <Card>
            <CardContent>
                <Typography variant="h5" fontWeight="bold">
                    {contrat_date_debut === contrat_date_fin ? (
                        datetime(contract.contrat_date_fin).format("D MMM YYYY")
                    ) : (
                        <>
                            {datetime(contract.contrat_date_debut).format("D MMM")} -{" "}
                            {datetime(contract.contrat_date_fin).format("D MMM YYYY")}
                        </>
                    )}
                </Typography>
            </CardContent>
            <Divider />
            <CardActions sx={{ px: 0 }}>
                <List dense sx={{ width: "100%" }}>
                    {showContractPdf ? (
                        <ListItem disablePadding>
                            <ListItemButton
                                disabled={contract.contrat_url === ""}
                                onClick={() => window.open(contract.contrat_url, "_blank")}
                            >
                                <ListItemIcon>
                                    <PictureAsPdfRoundedIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Voir mon contrat" />
                            </ListItemButton>
                        </ListItem>
                    ) : null}

                    {contract.document_contrat.map((document) => (
                        <ListItem key={document.document_id} disablePadding>
                            <ListItemButton
                                disabled={document.document_url === ""}
                                onClick={() => window.open(document.document_url, "_blank")}
                            >
                                <ListItemIcon>
                                    <PictureAsPdfRoundedIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary={document.document_titre} />
                            </ListItemButton>
                        </ListItem>
                    ))}

                    {contract.document_contrat.some(
                        (doc) => doc.document_titre === "Note de frais",
                    ) ? null : (
                        <ListItem
                            disablePadding
                            secondaryAction={
                                <IconButton
                                    onClick={() => navigate(`${contract.contrat_id}/costs`)}
                                >
                                    <KeyboardArrowRightIcon fontSize="small" />
                                </IconButton>
                            }
                        >
                            <ListItemButton
                                onClick={() => navigate(`${contract.contrat_id}/costs`)}
                            >
                                <ListItemIcon>
                                    <EuroRoundedIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Mes frais" />
                            </ListItemButton>
                        </ListItem>
                    )}
                </List>
            </CardActions>
        </Card>
    );
}

export default ContractCard;
