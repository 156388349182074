import "dayjs/locale/fr";

import { PropsWithChildren } from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider as MuiLocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import datetime from "../config/datetime";

export function LocalizationProvider({ children }: PropsWithChildren) {
    return (
        <MuiLocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={datetime}>
            {children}
        </MuiLocalizationProvider>
    );
}
