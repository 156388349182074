import { RefObject } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, InputAdornment, Stack, Typography } from "@mui/material";

import FormNumber from "../../../../components/form/FormNumber";
import { useGrappageValidate } from "../../api/useGrappageValidate";
import { usePostTransportInfos } from "../../api/usePostTransportInfos";
import { TransportInfosFormData } from "../../types/transport-infos-form";
import transportInfosSchema from "../../validation/transportInfosSchema";

type Props = {
    formRef: RefObject<HTMLFormElement>;
    defaultValues: TransportInfosFormData;
};

function TransportInfosForm({ formRef, defaultValues }: Props) {
    const navigate = useNavigate();
    const { folderId } = useParams();
    const form = useForm<TransportInfosFormData>({
        resolver: yupResolver(transportInfosSchema),
        defaultValues,
    });

    const grappageValidateQuery = useGrappageValidate();
    const handleOnSuccess = () => {
        grappageValidateQuery.mutate(folderId as string);
        navigate("/");
    };

    const transportMutation = usePostTransportInfos();
    const onSubmit = (formData: TransportInfosFormData) => {
        transportMutation.mutate({ folderId, ...formData }, { onSuccess: handleOnSuccess });
    };

    return (
        <FormProvider {...form}>
            <Stack spacing={5}>
                <form onSubmit={form.handleSubmit(onSubmit)} ref={formRef}>
                    <Stack spacing={5}>
                        <Stack spacing={2}>
                            <Typography>Cartons 1.3G</Typography>
                            <FormNumber name="contrat_nb_cartons_13G" label="Nombre de cartons" />
                            <FormNumber
                                name="contrat_poids_13G"
                                label="Poids"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">kg</InputAdornment>
                                    ),
                                }}
                            />
                        </Stack>

                        <Divider />

                        <Stack spacing={2}>
                            <Typography>Cartons 1.4G</Typography>
                            <FormNumber name="contrat_nb_cartons_14G" label="Nombre de cartons" />
                            <FormNumber
                                name="contrat_poids_14G"
                                label="Poids"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">kg</InputAdornment>
                                    ),
                                }}
                            />
                        </Stack>

                        <Divider />

                        <Stack spacing={2}>
                            <Typography>Cartons 1.4S</Typography>
                            <FormNumber name="contrat_nb_cartons_14S" label="Nombre de cartons" />
                            <FormNumber
                                name="contrat_poids_14S"
                                label="Poids"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">kg</InputAdornment>
                                    ),
                                }}
                            />
                        </Stack>
                    </Stack>
                </form>
            </Stack>
        </FormProvider>
    );
}

export default TransportInfosForm;
