import { Route, Routes } from "react-router-dom";

import ProtectedRoute from "../features/auth/ProtectedRoute";
import { useLocationScrollTop } from "../hooks";
import Page404 from "../pages/_errors/Page404";
import UnauthorizedPage from "../pages/_errors/UnauthorizedPage";
import DefaultLayout from "../pages/_layouts/DefaultLayout";
import ForgotPasswordPage from "../pages/auth/ForgotPasswordPage";
import LoginPage from "../pages/auth/LoginPage";
import LogoutPage from "../pages/auth/LogoutPage";
import ContractCostsPage from "../pages/contract/ContractCostsPage";
import ContractsPage from "../pages/contract/ContractsPage";
import HistoryPage from "../pages/contract/HistoryPage";
import DashboardPage from "../pages/dashboard/DashboardPage";
import ProfilePage from "../pages/profile/ProfilePage";
import RoadmapDetailsPage from "../pages/roadmap/RoadmapDetailsPage";
import RoadmapPage from "../pages/roadmap/RoadmapPage";
import StockFolderListPage from "../pages/stock/StockFolderListPage";
import StockGrappagePage from "../pages/stock/StockGrappagePage";
import StockRamassagePage from "../pages/stock/StockRamassagePage";
import StockStepChoicePage from "../pages/stock/StockStepChoicePage";
import StockStockagePage from "../pages/stock/StockStockagePage";
import WorkSitePage from "../pages/worksite/WorkSitePage";
import WorkSiteReportPage from "../pages/worksite/WorkSiteReportPage";
import { ContractType } from "../features/auth/types/contractType";

function AppRoutes() {
    useLocationScrollTop();

    return (
        <Routes>
            <Route path="/" element={<DefaultLayout />}>
                <Route path="" element={<ProtectedRoute allowedContrats={[ContractType.CDI]} />}>
                    <Route index element={<StockStepChoicePage />} />
                    <Route path="ramassage" element={<StockFolderListPage />} />
                    <Route path="ramassage/:folderId" element={<StockRamassagePage />} />
                    <Route path="grappage" element={<StockFolderListPage />} />
                    <Route path="grappage/:folderId" element={<StockGrappagePage />} />
                    <Route path="stockage" element={<StockFolderListPage />} />
                    <Route path="stockage/:folderId" element={<StockStockagePage />} />
                </Route>
                <Route element={<ProtectedRoute />}>
                    <Route path="dashboard" element={<DashboardPage />} />
                    <Route path="dashboard/profile" element={<ProfilePage />} />

                    <Route path="dashboard/worksites" element={<WorkSitePage />} />
                    <Route
                        path="dashboard/worksites/:id/rapport"
                        element={<WorkSiteReportPage />}
                    />

                    <Route path="dashboard/roadmaps" element={<RoadmapPage />} />
                    <Route path="dashboard/roadmaps/:id" element={<RoadmapDetailsPage />} />

                    <Route path="dashboard/contracts" element={<ContractsPage />} />
                    <Route
                        path="dashboard/contracts/:contractId/costs"
                        element={<ContractCostsPage />}
                    />
                    <Route path="dashboard/contracts/history" element={<HistoryPage />} />
                </Route>
                <Route path="unauthorized" element={<UnauthorizedPage />} />
            </Route>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/logout" element={<LogoutPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/*" element={<Page404 />} />
        </Routes>
    );
}

export default AppRoutes;
