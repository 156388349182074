import { useState } from "react";

import PictureAsPdfRoundedIcon from "@mui/icons-material/PictureAsPdfRounded";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
} from "@mui/material";

import datetime from "../../../config/datetime";
import { formatToEuro } from "../../../utils/number";
import { Contract } from "../types/contract";

type Props = {
    contract: Contract;
};

function ContractHistoryCard({ contract }: Props) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Card>
                <CardContent>
                    <Typography variant="h6" fontWeight="bold">
                        {datetime(contract.contrat_date_debut).format("DD MMM YYYY")} -{" "}
                        {datetime(contract.contrat_date_fin).format("DD MMM YYYY")}
                    </Typography>
                    <List dense>
                        <ListItem
                            disablePadding
                            secondaryAction={
                                <Typography>
                                    {formatToEuro(parseFloat(contract.reglement_total))}
                                </Typography>
                            }
                        >
                            <ListItemText primary="Salaire perçu" />
                        </ListItem>
                        <ListItem
                            disablePadding
                            secondaryAction={
                                <Typography>
                                    {formatToEuro(parseFloat(contract.total_frais_valide))}
                                </Typography>
                            }
                        >
                            <ListItemText primary="Mes frais" />
                        </ListItem>
                    </List>
                    <Button
                        onClick={() => setOpen(true)}
                        fullWidth
                        size="small"
                        variant="contained"
                    >
                        Informations règlement
                    </Button>
                </CardContent>
                <Divider />
                <CardActions sx={{ px: 0 }}>
                    <List dense sx={{ width: "100%" }}>
                        <ListItem disablePadding>
                            <ListItemButton
                                onClick={() => window.open(contract.contrat_url, "_blank")}
                            >
                                <ListItemIcon>
                                    <PictureAsPdfRoundedIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Voir mon contrat" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </CardActions>
            </Card>

            <Dialog maxWidth="xs" open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Règlement effectué</DialogTitle>
                <DialogContent dividers>
                    <Stack spacing={2}>
                        <Typography>
                            Ce contrat a été réglé le{" "}
                            {datetime(contract.reglement_date).format("LL")} par{" "}
                            {contract.reglement_type} pour un total de{" "}
                            {formatToEuro(parseFloat(contract.reglement_total))}*
                        </Typography>
                        <Typography variant="caption">
                            * La somme peut différer si vous aviez de l'alcool dans vos frais
                        </Typography>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setOpen(false)}>
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ContractHistoryCard;
