import { groupBy } from "../../utils/array";
import { Folder } from "./types/folder";
import { Table } from "./types/grappage";
import { Product } from "./types/product";

export const getFilteredFolders = (folders: Folder[], searchValue: string) => {
    return folders.filter(
        ({ nom, lieu }) =>
            nom.toLowerCase().includes(searchValue.toLowerCase()) ||
            lieu.toLowerCase().includes(searchValue.toLowerCase()),
    );
};

export const getFoldersByDate = (folders: Folder[]): { [key: string]: Folder[] } => {
    const groupedFolders: { [key: string]: Folder[] } = groupBy("date_tir", folders);

    const groupedAndSortedFolders: { [key: string]: Folder[] } = {};
    Object.entries(groupedFolders).forEach(([date, f]) => {
        groupedAndSortedFolders[date] = f.sort((a, b) =>
            // eslint-disable-next-line no-nested-ternary
            a.nom.toLowerCase() > b.nom.toLowerCase()
                ? 1
                : b.nom.toLowerCase() > a.nom.toLowerCase()
                ? -1
                : 0,
        );
    });

    return groupedAndSortedFolders;
};

export const getFilteredProducts = (products: Product[], searchValue: string) => {
    return products.filter(
        ({ nom, reference }) =>
            nom.toLowerCase().includes(searchValue.toLowerCase()) ||
            reference.toLowerCase().includes(searchValue.toLowerCase()),
    );
};

export const getProductsByDepot = (products: Product[]): { [key: string]: Product[] } => {
    // eslint-disable-next-line no-nested-ternary
    const sortedProducts = products.sort((a, b) => (a.nom > b.nom ? 1 : b.nom > a.nom ? -1 : 0));
    const groupedProducts = groupBy("depot", sortedProducts);

    return groupedProducts;
};

export const getFilteredTables = (tables: Table[], searchValue: string) => {
    const filteredTables = tables
        .map((table) => {
            const filteredLines = table.lignes?.filter(
                (product) =>
                    product.nom.toLowerCase().includes(searchValue.toLowerCase()) ||
                    product.reference.toLowerCase().includes(searchValue.toLowerCase()),
            );
            return { ...table, lignes: filteredLines };
        })
        .filter((table) => table !== undefined && table.lignes?.length > 0);

    return filteredTables;
};
