import { useQuery } from "@tanstack/react-query";

import { getContratsHistory } from "./actions";

export function useGetContractsHistory(date: string) {
    const query = useQuery({
        queryKey: ["contracts-history", date],
        queryFn: () => getContratsHistory(date),
    });

    return query;
}
