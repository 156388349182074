import { useQuery } from "@tanstack/react-query";

import { getUser } from "./actions";
import { QUERY_KEY } from "./config";

export function useGetUser() {
    const query = useQuery({
        queryKey: [QUERY_KEY],
        queryFn: getUser,
    });

    return query;
}
