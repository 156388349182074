import { Button, Container, Stack, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import {
    ErrorBoundary as ReactErrorBoundary,
    ErrorBoundaryProps as ReactErrorBoundaryProps,
    FallbackProps as ReactErrorFallbackProps,
} from "react-error-boundary";

export function ErrorFallback({ error, resetErrorBoundary }: ReactErrorFallbackProps) {
    return (
        <Container sx={{ py: 4 }} maxWidth="sm">
            <Stack alignItems="center" gap={3}>
                <Stack alignItems="center" gap={1}>
                    <Typography align="center">Il semblerait qu'il y ait un problème !</Typography>
                    <Typography align="center">{error.message}</Typography>
                </Stack>
                <Button onClick={resetErrorBoundary} fullWidth variant="contained">
                    Réessayer
                </Button>
                <Button onClick={() => window.location.reload()}>Actualiser la page</Button>
            </Stack>
        </Container>
    );
}

export type ErrorBoundaryProps = PropsWithChildren<
    Pick<ReactErrorBoundaryProps, "FallbackComponent" | "onError">
>;

export function ErrorBoundary({
    children,
    FallbackComponent = ErrorFallback,
    onError,
}: ErrorBoundaryProps) {
    return (
        <ReactErrorBoundary FallbackComponent={FallbackComponent} onError={onError}>
            {children}
        </ReactErrorBoundary>
    );
}
