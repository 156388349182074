import { useState } from "react";
import { useParams } from "react-router-dom";

import { Button, Container, Paper, Stack, Typography } from "@mui/material";

import LoadingSpinner from "../../components/LoadingSpinner";
import PageHeader from "../../components/PageHeader";
import datetime from "../../config/datetime";
import { useGetGrappageProducts } from "../../features/stock/api/useGetGrappageProducts";
import CancelGrappageDialog from "../../features/stock/components/grappage/CancelGrappageDialog";
import GrappageProductsList from "../../features/stock/components/grappage/GrappageProductsList";
import TransportDialog from "../../features/stock/components/transport/TransportDialog";

function StockGrappagePage() {
    const { folderId } = useParams();
    const [openCancel, setOpenCancel] = useState(false);
    const [openTransport, setOpenTransport] = useState(false);

    const grappageQuery = useGetGrappageProducts(folderId as string, {
        onSuccess: (data) => (data.grappe === 1 ? setOpenCancel(true) : setOpenCancel(false)),
    });

    const isComplete = !grappageQuery.data?.tableau?.find((tab) => tab.tableau_ok === 0);

    return (
        <>
            <PageHeader title="Grappage" />
            {grappageQuery.isLoading || !grappageQuery.data ? (
                <LoadingSpinner />
            ) : (
                <>
                    <Paper elevation={1}>
                        <Container maxWidth="sm" sx={{ pb: 2 }}>
                            <Stack alignItems="center">
                                <Typography variant="h5" fontWeight="bold" textAlign="center">
                                    {grappageQuery.data.nom}
                                </Typography>
                                <Typography>
                                    {datetime(grappageQuery.data.date).format("LL")}
                                </Typography>
                            </Stack>
                        </Container>
                    </Paper>
                    <Container sx={{ py: 4 }} maxWidth="sm">
                        <Stack spacing={3}>
                            {isComplete ? (
                                <Button onClick={() => setOpenTransport(true)} variant="contained">
                                    Valider grappage
                                </Button>
                            ) : null}
                            <GrappageProductsList tables={grappageQuery.data.tableau} />
                        </Stack>
                    </Container>

                    <CancelGrappageDialog open={openCancel} onClose={() => setOpenCancel(false)} />
                    <TransportDialog open={openTransport} onClose={() => setOpenTransport(false)} />
                </>
            )}
        </>
    );
}

export default StockGrappagePage;
