import { useQuery } from "@tanstack/react-query";

import { getContrats } from "./actions";
import { QUERY_KEY } from "./config";

export function useGetContracts() {
    const query = useQuery({
        queryKey: [QUERY_KEY],
        queryFn: () => getContrats(),
    });

    return query;
}
