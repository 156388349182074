import { useEffect, useState } from "react";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Button, Container, Modal, Paper, Stack, Tab, Typography } from "@mui/material";

import Scanner from "../../../../components/Scanner";
import { useLocalStorage } from "../../../../hooks";
import { Product } from "../../types/product";
import DouchetteForm from "./DouchetteForm";

type Props = {
    product: Product;
    open: boolean;
    onClose: () => void;
    onScan: (code: string) => void;
};

function ScannerModal({ open, onClose, product, onScan }: Props) {
    const { setItem, getItem } = useLocalStorage();
    const [value, setValue] = useState("douchette");

    useEffect(() => {
        const preference = getItem("stellartifice_scan_preference") || "douchette";
        setValue(preference);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeTab = (_event: React.SyntheticEvent<Element, Event>, newValue: string) => {
        setItem("stellartifice_scan_preference", newValue);
        setValue(newValue);
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{
                "& .MuiModal-backdrop": {
                    background: "rgba(0,0,0,0.9)",
                },
            }}
        >
            <Stack sx={{ height: "100%" }}>
                <Paper elevation={1}>
                    <Container maxWidth="sm" sx={{ py: 2 }}>
                        <Stack alignItems="center" spacing={1}>
                            <Typography fontWeight="bold" textAlign="center">
                                {product.nom}
                            </Typography>
                            <Typography fontWeight="bold">
                                Quantité : {product.quantite_ramassee} / {product.quantite}
                            </Typography>
                        </Stack>
                    </Container>
                </Paper>
                <TabContext value={value}>
                    <Stack alignItems="center">
                        <TabList onChange={handleChangeTab}>
                            <Tab label="Téléphone" value="phone" />
                            <Tab label="Douchette" value="douchette" />
                        </TabList>
                    </Stack>
                    <TabPanel value="phone" sx={{ height: "100%" }}>
                        <Stack
                            justifyContent="center"
                            sx={{ height: "100%", position: "relative" }}
                        >
                            <Scanner onScan={onScan} />
                        </Stack>
                    </TabPanel>
                    <TabPanel value="douchette" sx={{ height: "100%" }}>
                        <Stack justifyContent="center" sx={{ height: "100%" }}>
                            <DouchetteForm onSubmit={onScan} />
                        </Stack>
                    </TabPanel>
                </TabContext>
                <Stack sx={{ mb: 5, alignItems: "center" }}>
                    <Button variant="outlined" endIcon={<CloseRoundedIcon />} onClick={onClose}>
                        Fermer
                    </Button>
                </Stack>
            </Stack>
        </Modal>
    );
}

export default ScannerModal;
