import { useFormContext, useWatch } from "react-hook-form";

import { Divider } from "@mui/material";

import FormRadio from "../../../components/form/FormRadio";
import FormText from "../../../components/form/FormText";
import { ShotProblemCode } from "../types/shot-problem-code";

function ShotIsNotFiredForm() {
    const { control } = useFormContext();

    const shotNotFiredReason = useWatch({ control, name: "code_probleme" });

    return (
        <>
            <Divider />

            <FormRadio
                name="code_probleme"
                label="Pourquoi le tir n'a pas été effectué ?"
                options={[
                    { value: ShotProblemCode.CUSTOMER_CANCELLATION, label: "Annulation client" },
                    { value: ShotProblemCode.WEATHER_CANCELLATION, label: "Annulation météo" },
                    { value: ShotProblemCode.TECHNICAL_PROBLEM, label: "Problème technique" },
                ]}
            />

            {shotNotFiredReason === ShotProblemCode.TECHNICAL_PROBLEM ? (
                <FormText
                    name="probleme_tech_commentaire"
                    label="Quel a été le problème technique ?"
                    multiline
                />
            ) : null}
        </>
    );
}

export default ShotIsNotFiredForm;
