import { useState } from "react";

import ReceiptLongRoundedIcon from "@mui/icons-material/ReceiptLongRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import { Backdrop, Box, Fade, IconButton, ListItem, ListItemText, Modal } from "@mui/material";

import { formatToEuro } from "../../../utils/number";
import { CostType } from "../types/cost-type";

import type { Cost } from "../types/cost";

type Props = {
    cost: Cost;
    onDelete: () => void;
};

function CostItem({ cost, onDelete }: Props) {
    const [open, setOpen] = useState(false);

    const amount = parseFloat(cost.lier_contrat_frais_montant);

    return (
        <>
            <ListItem
                disablePadding
                secondaryAction={
                    <>
                        <IconButton onClick={() => setOpen(true)}>
                            <ReceiptLongRoundedIcon />
                        </IconButton>
                        <IconButton
                            color="error"
                            disabled={cost.lier_contrat_frais_valide === true}
                            onClick={onDelete}
                        >
                            <DeleteForeverRoundedIcon />
                        </IconButton>
                    </>
                }
            >
                <ListItemText
                    primary={formatToEuro(amount)}
                    secondary={Object.keys(CostType)[cost.lier_contrat_frais_type]}
                />
            </ListItem>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box
                        onClick={() => setOpen(false)}
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            width: "75%",
                            maxHeight: "75%",
                            overflow: "hidden",
                            transform: "translate(-50%, -50%)",
                        }}
                    >
                        <Box
                            sx={{ width: "100%", maxHeight: "100%", objectFit: "contain" }}
                            component="img"
                            src={cost.document_url}
                        />
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}
export default CostItem;
