import { Controller } from "react-hook-form";

import { FormControl, FormHelperText, InputLabel, NativeSelect } from "@mui/material";

type Props = {
    name: string;
    label: string;
    defaultValue?: string;
    options: {
        value: string;
        label: string;
    }[];
};
function FormSelect({ name, label, defaultValue = "", options }: Props) {
    return (
        <Controller
            name={name}
            defaultValue={defaultValue || options[0].value}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl fullWidth error={!!error}>
                    <InputLabel variant="standard" htmlFor={name}>
                        {label}
                    </InputLabel>
                    <NativeSelect onChange={onChange} value={value} inputProps={{ name, id: name }}>
                        {options.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </NativeSelect>
                    {error ? <FormHelperText sx={{ mx: 0 }}>{error.message}</FormHelperText> : null}
                </FormControl>
            )}
        />
    );
}

export default FormSelect;
