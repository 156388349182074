import { useRef } from "react";
import { useParams } from "react-router-dom";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import { useGetTransportInfos } from "../../api/useGetTransportInfos";
import TransportInfosForm from "./TransportInfosForm";

type Props = {
    open: boolean;
    onClose: () => void;
};

function TransportDialog({ open, onClose }: Props) {
    const { folderId } = useParams();
    const formRef = useRef<HTMLFormElement>(null);

    const transportQuery = useGetTransportInfos(folderId as string);

    const handleOnClickConfirm = () => {
        formRef.current?.requestSubmit();
    };

    return (
        <Dialog open={open} disableRestoreFocus onClose={onClose}>
            <DialogTitle>Informations de transport</DialogTitle>
            <DialogContent>
                {transportQuery.data ? (
                    <TransportInfosForm formRef={formRef} defaultValues={transportQuery.data} />
                ) : null}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Annuler</Button>
                <Button onClick={handleOnClickConfirm}>Confirmer</Button>
            </DialogActions>
        </Dialog>
    );
}

export default TransportDialog;
