import { useNavigate } from "react-router-dom";

import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PictureAsPdfRoundedIcon from "@mui/icons-material/PictureAsPdfRounded";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";

import datetime from "../../../config/datetime";
import { WorkSite } from "../types/work-site";

type Props = {
    workSite: WorkSite;
};

function WorkSiteCard({ workSite }: Props) {
    const { palette } = useTheme();
    const navigate = useNavigate();

    const createTextFromDate = (date: string) => {
        const today = datetime();
        const endDate = datetime(date);
        const daysDiff = endDate.diff(today, "day");
        if (daysDiff === 0) {
            return `${endDate.format("LL")} · Aujourd'hui`;
        }
        return `${endDate.format("LL")} · Dans ${daysDiff} jours`;
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h5" fontWeight="bold">
                    {workSite.nom}
                </Typography>
                <List dense>
                    <ListItem disablePadding>
                        <ListItemText primary={workSite.lieu_tir} />
                    </ListItem>
                    {workSite.dates.map((date) => (
                        <ListItem key={date} disablePadding>
                            <ListItemText primary={createTextFromDate(date)} />
                        </ListItem>
                    ))}
                </List>
            </CardContent>
            <Divider />
            <CardActions sx={{ px: 0 }}>
                <List dense sx={{ width: "100%" }}>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => window.open(workSite.plan_tir, "_blank")}>
                            <ListItemIcon>
                                <PictureAsPdfRoundedIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Plan de tir" />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={() => window.open(workSite.implantation_tir, "_blank")}
                        >
                            <ListItemIcon>
                                <PictureAsPdfRoundedIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Implantation de tir" />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={() => window.open(workSite.document_transport, "_blank")}
                        >
                            <ListItemIcon>
                                <PictureAsPdfRoundedIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Document de transport" />
                        </ListItemButton>
                    </ListItem>

                    {"rapport" in workSite ? (
                        <ListItem
                            disablePadding
                            secondaryAction={
                                workSite.rapport ? (
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        gap={1}
                                        paddingRight={1}
                                    >
                                        <Typography color={palette.success.main} variant="button">
                                            Fait
                                        </Typography>
                                        <CheckRoundedIcon color="success" fontSize="small" />
                                    </Stack>
                                ) : (
                                    <Button
                                        onClick={() => navigate(`${workSite.id}/rapport`)}
                                        endIcon={<KeyboardArrowRightIcon fontSize="small" />}
                                    >
                                        À faire
                                    </Button>
                                )
                            }
                        >
                            <ListItemButton
                                disabled={workSite.rapport}
                                onClick={() => navigate(`${workSite.id}/rapport`)}
                            >
                                <ListItemIcon>
                                    <AssignmentRoundedIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Rapport de tir" />
                            </ListItemButton>
                        </ListItem>
                    ) : null}
                </List>
            </CardActions>
        </Card>
    );
}

export default WorkSiteCard;
