import { Outlet } from "react-router-dom";

import { Box } from "@mui/material";

import Navigation from "../../layouts/Navigation";
import { ErrorBoundary } from "../../components/ErrorBoundary";
import { logError } from "../../config/sentry";

function DefaultLayout() {
    return (
        <ErrorBoundary onError={logError}>
            <Box sx={{ paddingBottom: "56px" }}>
                <Outlet />
                <Navigation />
            </Box>
        </ErrorBoundary>
    );
}

export default DefaultLayout;
