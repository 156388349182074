import { useNavigate } from "react-router-dom";

import { Alert, Button, Container, Stack } from "@mui/material";

import ForgotPasswordForm from "../../features/auth/ForgotPasswordForm";
import { useWindowSize } from "../../hooks";

function ForgotPasswordPage() {
    const navigate = useNavigate();
    const { height } = useWindowSize();

    return (
        <Container maxWidth="sm">
            <Stack sx={{ height }} justifyContent="center" spacing={5}>
                <Alert severity="info">
                    Renseignez un email valide pour recevoir des instructions afin de réinitialiser
                    votre mot de passe
                </Alert>
                <ForgotPasswordForm />
                <Button onClick={() => navigate("/login")}>Retour</Button>
            </Stack>
        </Container>
    );
}

export default ForgotPasswordPage;
