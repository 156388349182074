import { boolean, mixed, number, object, ObjectSchema, string } from "yup";

import { CustomerReview } from "../types/customer-review";
import { ShotProblemCode } from "../types/shot-problem-code";
import { WorkSiteReportFormData } from "../types/work-site-report-form";

const workSiteReportSchema: ObjectSchema<WorkSiteReportFormData> = object().shape({
    effectue: boolean().required("Le champ est requis"),

    // Shot not fired
    code_probleme: mixed<ShotProblemCode>().when("effectue", {
        is: false,
        then: (schema) =>
            schema
                .oneOf(Object.values(ShotProblemCode), "Cette valeur n'est pas valide")
                .required("Le champ est requis"),
        otherwise: (schema) => schema.notRequired(),
    }),
    probleme_tech_commentaire: string().when(["effectue", "code_probleme"], {
        is: (effectue: boolean, code_probleme: ShotProblemCode) => {
            return effectue === false && code_probleme === ShotProblemCode.TECHNICAL_PROBLEM;
        },
        then: (schema) => schema.required("Le champ est requis"),
        otherwise: (schema) => schema.notRequired(),
    }),

    // Shot fired
    duree_respecte: boolean().when("effectue", {
        is: true,
        then: (schema) => schema.required("Le champ est requis"),
        otherwise: (schema) => schema.notRequired(),
    }),
    duree_reel: number().when(["effectue", "duree_respecte"], {
        is: (effectue: boolean, duree_respecte: boolean) => {
            return effectue === true && duree_respecte === false;
        },
        then: (schema) =>
            schema
                .required("La durée est requise")
                .min(0, ({ min }) => `La durée doit être supérieure ou égale à ${min}`),
        otherwise: (schema) => schema.notRequired(),
    }),

    blancs: boolean().when("effectue", {
        is: true,
        then: (schema) => schema.required("Le champ est requis"),
        otherwise: (schema) => schema.notRequired(),
    }),
    blancs_raison: string().when(["effectue", "blancs"], {
        is: (effectue: boolean, blancs: boolean) => {
            return effectue === true && blancs === true;
        },
        then: (schema) => schema.required("Le champ est requis"),
        otherwise: (schema) => schema.notRequired(),
    }),

    client_contact: boolean().when("effectue", {
        is: true,
        then: (schema) => schema.required("Le champ est requis"),
        otherwise: (schema) => schema.notRequired(),
    }),
    client_avis: mixed<CustomerReview>().when(["effectue", "client_contact"], {
        is: (effectue: boolean, client_contact: boolean) => {
            return effectue === true && client_contact === true;
        },
        then: (schema) =>
            schema
                .oneOf(Object.values(CustomerReview), "Cette valeur n'est pas valide")
                .required("Le champ est requis"),
        otherwise: (schema) => schema.notRequired(),
    }),

    retour_autre: boolean().when("effectue", {
        is: true,
        then: (schema) => schema.required("Le champ est requis"),
        otherwise: (schema) => schema.notRequired(),
    }),
    retour_commentaire: string().when(["effectue", "retour_autre"], {
        is: (effectue: boolean, retour_autre: boolean) => {
            return effectue === true && retour_autre === true;
        },
        then: (schema) => schema.required("Le champ est requis"),
        otherwise: (schema) => schema.notRequired(),
    }),
});

export default workSiteReportSchema;
