import { useNavigate } from "react-router-dom";

import AssignmentTurnedInRoundedIcon from "@mui/icons-material/AssignmentTurnedInRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import EngineeringSharpIcon from "@mui/icons-material/EngineeringSharp";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
    Button,
    Container,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
} from "@mui/material";

import LoadingSpinner from "../../components/LoadingSpinner";
import PageHeader from "../../components/PageHeader";
import { useGetAllFolders } from "../../features/stock/api/useGetAllFolders";

function StockStepChoicePage() {
    const navigate = useNavigate();

    const foldersQuery = useGetAllFolders();
    const isLoading = [0, 1, 2].find((type) => foldersQuery[type].isLoading) !== undefined;

    const LINKS = [
        {
            title: "Ramassage",
            icon: <EngineeringSharpIcon />,
            onClick: () => navigate("ramassage"),
            quantity: foldersQuery[0].data?.filter((folder) => folder.valider === 0).length,
        },
        {
            title: "Grappage",
            icon: <DescriptionRoundedIcon />,
            onClick: () => navigate("grappage"),
            quantity: foldersQuery[1].data?.filter((folder) => folder.valider === 0).length,
        },
        {
            title: "Stockage",
            icon: <AssignmentTurnedInRoundedIcon />,
            onClick: () => navigate("stockage"),
            quantity: foldersQuery[2].data?.filter((folder) => folder.valider === 0).length,
        },
    ];

    return (
        <>
            <PageHeader showBackButton={false} title="Sélectionnez une étape" />
            <Container sx={{ py: 4 }} maxWidth="sm">
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <Stack spacing={3}>
                        <List>
                            {LINKS.map((link, index) => (
                                <ListItem
                                    key={link.title}
                                    divider={index !== LINKS.length - 1}
                                    disablePadding
                                    secondaryAction={
                                        <IconButton onClick={link.onClick}>
                                            <KeyboardArrowRightIcon />
                                        </IconButton>
                                    }
                                >
                                    <ListItemButton sx={{ py: 2 }} onClick={link.onClick}>
                                        <ListItemIcon>
                                            <Button
                                                sx={{
                                                    pointerEvents: "none",
                                                    minWidth: 0,
                                                    borderRadius: 50,
                                                    width: 40,
                                                    height: 40,
                                                }}
                                                variant="outlined"
                                                disabled={link.quantity === 0}
                                            >
                                                {link.quantity}
                                            </Button>
                                        </ListItemIcon>
                                        <ListItemText primary={link.title} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Stack>
                )}
            </Container>
        </>
    );
}

export default StockStepChoicePage;
