import md5 from "crypto-js/md5";

import apiClient from "../../../config/api-client";
import { ForgotPasswordFormData } from "../types/forgot-password-form";
import { ENDPOINT } from "./config";

import type { LoginFormData } from "../types/login-form";

export async function login(request: LoginFormData): Promise<string> {
    const hashPassword = md5(request.password);

    const response = await apiClient.post("jwt-authentication.php", {
        email: request.email,
        password: hashPassword.toString(),
    });

    return response.data;
}

export async function forgotPassword(request: ForgotPasswordFormData): Promise<string> {
    const response = await apiClient.post(ENDPOINT, {
        classe: "tf_profil",
        methode: "form_recuperation_mot_de_passe",
        params: [request.email],
    });

    return response.data;
}
