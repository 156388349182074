import { Container, Stack } from "@mui/material";

import LoadingSpinner from "../../components/LoadingSpinner";
import PageHeader from "../../components/PageHeader";
import { useGetWorkSites } from "../../features/worksite/api/useGetWorkSites";
import WorkSiteCard from "../../features/worksite/components/WorkSiteCard";

function WorkSitePage() {
    const workSiteQuery = useGetWorkSites();

    return (
        <>
            <PageHeader title="Chantiers en cours" />
            <Container sx={{ py: 4 }} maxWidth="sm">
                {workSiteQuery.isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <Stack spacing={3}>
                        {workSiteQuery.data?.map((workSite) => (
                            <WorkSiteCard key={workSite.id} workSite={workSite} />
                        ))}
                    </Stack>
                )}
            </Container>
        </>
    );
}

export default WorkSitePage;
