import { useMutation } from "@tanstack/react-query";

import { queryClient } from "../../../config/react-query";
import { cartonLine } from "./actions";

export function useCartonLine(folderId: string) {
    const mutation = useMutation({
        mutationFn: cartonLine,
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ["folders", "grappage", folderId],
            });
        },
    });

    return mutation;
}
