import { useParams } from "react-router-dom";

import { List, Stack, Typography } from "@mui/material";

import LoadingSpinner from "../../../components/LoadingSpinner";
import { useGetCosts } from "../api/useGetCosts";
import { useDeleteCost } from "../api/useDeleteCost";
import CostItem from "./CostItem";

type Params = {
    contractId: string;
};

function CostsList() {
    const { contractId } = useParams() as Params;
    const costsQuery = useGetCosts(contractId);
    const deleteCostMutation = useDeleteCost(contractId);

    const handleDeleteCost = (id: string) => {
        deleteCostMutation.mutate(id);
    };

    if (costsQuery.isLoading) {
        return <LoadingSpinner />;
    }

    if (!costsQuery.data || !costsQuery.data.length) {
        return <Typography>Vous n'avez enregistré aucun frais</Typography>;
    }

    return (
        <Stack>
            <Typography>Mes frais enregistrés</Typography>
            <List>
                {costsQuery.data.map((cost) => (
                    <CostItem
                        key={cost.lier_contrat_frais_id}
                        cost={cost}
                        onDelete={() => handleDeleteCost(cost.lier_contrat_frais_id)}
                    />
                ))}
            </List>
        </Stack>
    );
}

export default CostsList;
