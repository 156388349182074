import { useState } from "react";
import { useParams } from "react-router-dom";

import { Button, Container, Paper, Stack, Typography } from "@mui/material";

import LoadingSpinner from "../../components/LoadingSpinner";
import PageHeader from "../../components/PageHeader";
import datetime from "../../config/datetime";
import { useGetStockageInfos } from "../../features/stock/api/useGetStockageInfos";
import DepotItem from "../../features/stock/components/stockage/DepotItem";
import DepotsList from "../../features/stock/components/stockage/DepotsList";
import TransportDialog from "../../features/stock/components/transport/TransportDialog";

function StockStockagePage() {
    const { folderId } = useParams();
    const [openTransport, setOpenTransport] = useState(false);

    const stockageQuery = useGetStockageInfos(folderId as string);

    return (
        <>
            <PageHeader title="Ramassage" />
            {stockageQuery.isLoading || !stockageQuery.data ? (
                <LoadingSpinner />
            ) : (
                <>
                    <Paper elevation={1}>
                        <Container maxWidth="sm" sx={{ pb: 2 }}>
                            <Stack alignItems="center">
                                <Typography variant="h5" fontWeight="bold" textAlign="center">
                                    {stockageQuery.data.nom}
                                </Typography>
                                <Typography>
                                    {datetime(stockageQuery.data.date).format("LL")}
                                </Typography>
                            </Stack>
                        </Container>
                    </Paper>
                    <Container sx={{ py: 4 }} maxWidth="sm">
                        <Stack spacing={3}>
                            <Button onClick={() => setOpenTransport(true)} variant="contained">
                                Données de transport
                            </Button>
                            <Typography>
                                Poids du dossier : {stockageQuery.data.poids_dossier} kg
                            </Typography>

                            {stockageQuery.data.stock_depot ? (
                                <DepotItem depotNumber={stockageQuery.data.stock_depot} />
                            ) : (
                                <DepotsList
                                    depots={stockageQuery.data.depot}
                                    folderWeight={stockageQuery.data.poids_dossier}
                                />
                            )}
                        </Stack>
                    </Container>

                    <TransportDialog open={openTransport} onClose={() => setOpenTransport(false)} />
                </>
            )}
        </>
    );
}

export default StockStockagePage;
